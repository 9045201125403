import colors from "../Material/theme/colors";

export const QUERY_PARAM_TYPE_GEO = 'typeGeo';
export const QUERY_PARAM_ID_REG = 'idReg';
export const QUERY_PARAM_ID_DEP = 'idDep';
export const QUERY_PARAM_ID_ETAB = 'idEtab';
export const QUERY_PARAM_POPULATION = 'population';
export const QUERY_PARAM_IS_SDC_ETAB = 'isSdc';
export const QUERY_PARAM_STATUT_ETAB = 'statut';
export const QUERY_PARAM_CHAMP_ETAB = 'champ';
export const QUERY_PARAM_IS_CLIENT_ETAB = 'isClient';

export const PARAM_BOOLEAN_TRUE = 'on';
export const PARAM_TOUS = 'tous';
export const PARAM_TYPE_GEO_DEP = 'dep';
export const PARAM_TYPE_GEO_REG = 'reg';
export const PARAM_POPULATION_DNP = 'dnp';
export const PARAM_POPULATION_PATIENT = 'patients';
export const PARAM_STATUT_ETAB_PUBLIC = 'pub';
export const PARAM_STATUT_ETAB_PRIVE = 'priv';
export const PARAM_CHAMP_ETAB_MCO = 'mco';
export const PARAM_CHAMP_ETAB_SSR = 'ssr';



export const ACTION_FETCH_SIMULATIONS_PENDING = 'ACTION_FETCH_SIMULATIONS_PENDING';
export const ACTION_FETCH_SIMULATIONS_FULFILLED = 'ACTION_FETCH_SIMULATIONS_FULFILLED';
export const ACTION_FETCH_SIMULATIONS_ERROR = 'ACTION_FETCH_SIMULATIONS_ERROR';

export const ACTION_CHANGE_MAP_MIN_MAX = 'ACTION_CHANGE_MAP_MIN_MAX';
export const ACTION_ZONE_OVER = 'ACTION_ZONE_OVER';
export const ACTION_ZONE_OUT = 'ACTION_ZONE_OUT';

export const ACTION_CHANGE_ETAB_LIST = 'ACTION_CHANGE_ETAB_LIST';

export const ACTION_TOGGLE_SEARCH = "ACTION_TOGGLE_SEARCH";
export const ACTION_FETCH_SEARCH_PENDING = 'ACTION_FETCH_SEARCH_PENDING';
export const ACTION_FETCH_SEARCH_FULFILLED = 'ACTION_FETCH_SEARCH_FULFILLED';
export const ACTION_FETCH_SEARCH_ERROR = 'ACTION_FETCH_SEARCH_ERROR';
export const ACTION_CLEAN_SEARCH = "ACTION_CLEAN_SEARCH";

export const GRADIANT_MIN_COLOR = colors.sunflowerYellow;
export const GRADIANT_MAX_COLOR = colors.turquoiseBlue;
export const GRADIANT_OPACITY = 0.6;

export const CIRCLE_MARKER_LIMIT_SIZE = 30;
export const CIRCLE_MARKER_MIN_SIZE = 20;
export const CIRCLE_MARKER_MAX_SIZE = 100;
export const MAP_DEFAULT_ZOOM = 6;
export const MAP_REGION_ZOOM = 8;
export const MAP_DEP_ZOOM = 9;
export const MAP_DEFAULT_CENTER = [46.85, 2.3518];
export const MAP_LARGE_CLUSTER_SIZE = 5;
export const MAP_ATTRIBUTION = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attribution">CARTO</a>, <a href="https://www.hevaweb.com">By HEVA</a>';
export const TILE_LAYER_URL_NOLABELS = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png';
export const TILE_LAYER_URL_ONLY_LABELS = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png';