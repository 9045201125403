import {
    EDIT_VALUES_CLEAR_ERROR,
    EDIT_VALUES_CLEAR_ERRORS,
    EDIT_VALUES_ERROR,
    EDIT_VALUES_FULFILLED,
    EDIT_VALUES_PENDING
} from "../../constants/edition";

export const INITIAL_STATE = {
    pending: 0,
    error: 0,
    saveId: 0
}

export default function listEditionReducer (state = INITIAL_STATE, {type}) {
    switch (type) {
        case EDIT_VALUES_PENDING : {
            return {
                ...state,
                pending: state.pending + 1,
                saveId: state.saveId + 1,
            }
        }
        case EDIT_VALUES_FULFILLED : {
            const pending = state.pending - 1;
            const error = state.error;

            return {
                ...state,
                pending,
                saveId: pending === 0 && error === 0
                    ? 0
                    : state.saveId
            }
        }
        case EDIT_VALUES_ERROR : {
            const pending = state.pending - 1;
            const error = state.error + 1;

            return {
                ...state,
                pending,
                error,
                saveId: pending === 0 && error === 0
                    ? 0
                    : state.saveId
            }
        }
        case EDIT_VALUES_CLEAR_ERROR : {
            const pending = state.pending;
            const error = state.error - 1;

            return {
                ...state,
                error,
                saveId: pending === 0 && error === 0
                    ? 0
                    : state.saveId
            }
        }
        case EDIT_VALUES_CLEAR_ERRORS : {
            const pending = state.pending;
            const error = 0;

            return {
                ...state,
                error,
                saveId: pending === 0 && error === 0
                    ? 0
                    : state.saveId
            }
        }
    }
    return state;
}