import {
    SIMULATION_CLEAN_SAVE_ERRORS,
    SIMULATION_FETCH_FULFILLED,
    SIMULATION_SAVE,
    SIMULATION_SAVE_ERROR,
    SIMULATION_SAVE_FULFILLED
} from "../../constants/simulation";
import editionReducer, {INITIAL_STATE as EDITION_INITIAL_STATE} from "../edition/editionReducer";
import {
    EDIT_VALUES_CLEAR_ERRORS,
    EDIT_VALUES_ERROR,
    EDIT_VALUES_FULFILLED,
    EDIT_VALUES_PENDING
} from "../../constants/edition";

export const INITIAL_STATE = {
    slug: null,
    mainValue : 0,
    benchmarkValue: 0,
    isBenchmarkEdited: false,
    edition: EDITION_INITIAL_STATE,
}

export default function valuesReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case SIMULATION_FETCH_FULFILLED: {
            const {slug, mainValue, benchmarkValue, isBenchmarkEdited} = payload;
            return {
                ...state,
                slug,
                mainValue,
                benchmarkValue,
                isBenchmarkEdited
            }
        }
        case SIMULATION_SAVE: {
            const {saveId, value, isBenchmark, isBenchmarkReset} = payload;
            const mainValue = isBenchmark ? state.mainValue : value;
            const benchmarkValue = !isBenchmark && state.isBenchmarkEdited ? state.benchmarkValue : value;
            const isBenchmarkEdited = isBenchmarkReset ? false : (isBenchmark || state.isBenchmarkEdited);

            return {
                ...state,
                mainValue,
                benchmarkValue,
                isBenchmarkEdited,
                edition: editionReducer(state.edition, {
                    type: EDIT_VALUES_PENDING,
                    payload: {
                        saveId,
                        values: {
                            mainValue: [state.mainValue, mainValue],
                            benchmarkValue: [state.benchmarkValue, benchmarkValue],
                            isBenchmarkEdited: [state.isBenchmarkEdited, isBenchmarkEdited]
                        }
                    }
                })
            }
        }
        case SIMULATION_SAVE_FULFILLED:
        case SIMULATION_SAVE_ERROR: {
            const {saveId} = payload;

            return {
                ...state,
                edition: editionReducer(state.edition, {
                    type: type === SIMULATION_SAVE_FULFILLED ? EDIT_VALUES_FULFILLED : EDIT_VALUES_ERROR,
                    payload: {saveId}
                })
            }
        }
        case SIMULATION_CLEAN_SAVE_ERRORS: {
            return {
                ...state,
                edition: editionReducer(state.edition, {type: EDIT_VALUES_CLEAR_ERRORS})
            }
        }
    }

    return state;
}