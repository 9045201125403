import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {fetch} from "../actions/defaultValues/defaultValuesAction";
import {useHistory} from "react-router";
import {ERROR_PAGE} from "../constants/router";
import useRouter from "../hooks/router/useRouter";

const DefaultValuesProvider = ({children}) => {
    const history = useHistory();
    const getUrl = useRouter();
    const dispatch = useDispatch();
    const error = useSelector(state => state.defaultValues.fetchError);
    const saveError = useSelector(state => state.defaultValues.saveError);

    useEffect(() => {
        dispatch(fetch());
    }, []);

    useEffect(() => {
        if(error || saveError) {
            // history.push(getUrl({page: ERROR_PAGE}))
        }
    }, [error, saveError]);

    if(error || saveError) return <div />;
    return children;
};

DefaultValuesProvider.propTypes = {
    children: PropTypes.node
};

DefaultValuesProvider.defaultProps = {
    children: null
};

export default DefaultValuesProvider;