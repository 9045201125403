import {
    ACTION_LOGIN_ERROR, ACTION_LOGIN_MDP, ACTION_LOGIN_MDP_ERROR, ACTION_LOGIN_MDP_SUCCES,
    ACTION_LOGIN_PENDING,
    ACTION_LOGIN_SUCCES,
    ACTION_LOGOUT
} from "../../constants/authentication";
import jwtDecode from "jwt-decode";
import {
    USER_PASSWORD_ERROR, USER_PASSWORD_PENDING,
    USER_PASSWORD_SUCCES,
    USER_SAVE_ERROR,
    USER_SAVE_PENDING, USER_SAVE_SUCCES,
    USER_SET
} from "../../constants/user";

const localTokenStorage = localStorage.getItem('token');
let user = {};
let loggedIn = false;

if(localTokenStorage){
    const decodedToken = jwtDecode(localTokenStorage);
    user = decodedToken.user || {};

    loggedIn = true;
}

const INITIAL_USER_STATE = {
    id: null,
    username: null,
    email: null,
    nom: null,
    prenom: null,
    sharedInformations: false
};

const INITIAL_STATE = {
    loggedIn,
    pending: false,
    error: false,
    mdpSend: false,
    mdpSendPending: false,
    mdpSendError: false,
    user: {
        ...INITIAL_USER_STATE,
        ...user
    }
};

export default function authenticationReducer (state = INITIAL_STATE, action) {
    switch(action.type) {
        case ACTION_LOGIN_PENDING : {
            return {
                ...state,
                loggedIn: false,
                pending: true,
                error: false
            }
        }
        case ACTION_LOGIN_ERROR: {
            return {
                ...state,
                pending: false,
                error: true
            }
        }
        case ACTION_LOGIN_SUCCES: {
            const {token} = action.payload;
            const decodedToken = jwtDecode(token);
            const user = decodedToken.user || {};

            return {
                ...state,
                loggedIn: true,
                pending: false,
                user: {
                    ...state.user,
                    ...user
                }
            }
        }
        case ACTION_LOGOUT : {
            return {
                ...state,
                loggedIn: false,
                user: {...INITIAL_USER_STATE}
            }
        }
        case USER_PASSWORD_SUCCES:
        case USER_SAVE_SUCCES: {
            return {
                ...state,
                pending: false,
                error: false
            }
        }
        case USER_PASSWORD_PENDING : {
            return {
                ...state,
                pending: true,
                error: false
            }
        }
        case USER_SAVE_PENDING: {
            const {slug, value} = action.payload;
            let user =  {...state.user};
            user[slug] = value;

            return {
                ...state,
                user,
                pending: true,
                error: false
            }
        }
        case USER_PASSWORD_ERROR: {
            return {
                ...state,
                pending: false,
                error: true
            }
        }
        case USER_SAVE_ERROR: {
            const {user} = action.payload;

            return {
                ...state,
                user,
                pending: false,
                error: true
            }
        }
        case ACTION_LOGIN_MDP: {
            return {
                ...state,
                mdpSendPending: true,
                mdpSend: false,
                mdpSendError: false
            }
        }
        case ACTION_LOGIN_MDP_SUCCES: {
            return {
                ...state,
                mdpSendPending: false,
                mdpSend: true,
                mdpSendError: false
            }
        }
        case ACTION_LOGIN_MDP_ERROR: {
            return {
                ...state,
                mdpSendPending: false,
                mdpSend: false,
                mdpSendError: true
            }
        }
    }
    return state;
}
