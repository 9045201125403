import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import {fade} from "@material-ui/core/styles/colorManipulator";
import colors from "./theme/colors"

const styles = theme => ({
    text: {
        color: theme.palette.text.contrastText,
        '&:hover': {
            color: theme.palette.grey[100],
            backgroundColor: 'transparent',
            '& .MuiButton-startIcon': {
                color: fade(colors.turquoiseBlue, 0.3),
            },
            '& .MuiButton-endIcon': {
                color: fade(colors.turquoiseBlue, 0.3),
            }
        },
        '& .MuiButton-startIcon': {
            color: fade(colors.turquoiseBlue, 0.5),
        },
        '& .MuiButton-endIcon': {
            color: fade(colors.turquoiseBlue, 0.5),
        }
    }
});

const ContrastedTextButton = props => {
    const overrideProps = {
        ...props,
        variant: 'text'
    }

    return <Button {...overrideProps} />;
};

export default withStyles(styles)(ContrastedTextButton);
