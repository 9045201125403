import sizes from "../sizes";

const DialogContent = theme => ({
    root: {
        padding: `${sizes.dialogInnerVPadding}px ${sizes.dialogHPadding}px ${sizes.dialogBottomPadding}px ${sizes.dialogHPadding}px`,
        '&:first-child': {
            paddingTop: `${sizes.dialogTopPadding}px`
        }
    }
});

export default DialogContent;