export const JOURS = [
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
    'dimanche',
];

export const SERVICES = [
    'oncologie',
    'soin-palliatif',
    'chirurgie-generale',
    'chirurgie-orthopedique',
    'mpr',
    'chirurgie-oncologique',
    'chirurgie-rachis',
    'chirurgie-digestive',
    'chirurgie-thoracique',
    'autre',
];


/**
 * VALEURS ENREGISTRÉES
 */
export const SEJOUR_ANNUEL              = "sejour-annuel";
export const CONSOMMABLE_ANNUEL         = "consommable-annuel";
export const CONSOMMABLE_PAR_SEJOUR     = "consommable-par-sejour";
export const CONSOMMATION_ZONE_PETITE   = "consommation-zone-petite";
export const CONSOMMATION_ZONE_MOYENNE  = "consommation-zone-moyenne";
export const CONSOMMATION_ZONE_GRANDE   = "consommation-zone-grande";
export const LIT_TOTAL                  = "lit-total";
export const TEMPS_LIT_SEJOUR_HEURE     = "temps-lit-sejour-heure";
export const TEMPS_IDE_SEJOUR_HEURE     = "temps-ide-sejour-heure";
export const MONTANT_GHM                = "montant-ghm";
export const COEFF_GEOGRAPHIQUE         = "coeff-geographique";
export const AUTRE_RECETTE              = "autre-recette";
export const MONTANT_CONSOMMABLE        = "montant-consommable";
export const DEPENSE_MEDECIN            = "depense-medecin";
export const DEPENSE_IDE                = "depense-ide";
export const DEPENSE_STRUCTURE          = "depense-structure";
export const DEPENSE_AUTRE              = "depense-autre";

export const JOUR_AM_HORAIRE            = '-am-horaire';
export const JOUR_AM_HORAIRE_DEBUT      = '-am-horaire-debut';
export const JOUR_AM_LIT                = '-am-lit';
export const JOUR_PM_HORAIRE            = '-pm-horaire';
export const JOUR_PM_HORAIRE_DEBUT      = '-pm-horaire-debut';
export const JOUR_PM_LIT                = '-pm-lit';
export const PLANNING_EDITABLE_CHAMPS   = [
    JOUR_AM_HORAIRE,
    JOUR_AM_HORAIRE_DEBUT,
    JOUR_AM_LIT,
    JOUR_PM_HORAIRE,
    JOUR_PM_HORAIRE_DEBUT,
    JOUR_PM_LIT,
];

export const SERVICE_SEJOUR_TOTAL_ANNUEL            = "-sejour-total-annuel"
export const SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL  = "-part-sejour-douloureux-annuel"
export const SERVICE_CONSOMMATION_PAR_ZONE          = "-consommation-par-zone"
export const SERVICE_EDITABLE_CHAMPS                = [
        SERVICE_SEJOUR_TOTAL_ANNUEL,
        SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL,
        SERVICE_CONSOMMATION_PAR_ZONE,
];

export const EDITABLE_CHAMPS = [
    SEJOUR_ANNUEL,
    CONSOMMABLE_ANNUEL,
    CONSOMMABLE_PAR_SEJOUR,
    CONSOMMATION_ZONE_PETITE,
    CONSOMMATION_ZONE_MOYENNE,
    CONSOMMATION_ZONE_GRANDE,
    LIT_TOTAL,
    TEMPS_LIT_SEJOUR_HEURE,
    TEMPS_IDE_SEJOUR_HEURE,
    MONTANT_GHM,
    COEFF_GEOGRAPHIQUE,
    AUTRE_RECETTE,
    MONTANT_CONSOMMABLE,
    DEPENSE_MEDECIN,
    DEPENSE_IDE,
    DEPENSE_STRUCTURE,
    DEPENSE_AUTRE,
    ...JOURS.reduce((accu, jour) => [...accu, ...PLANNING_EDITABLE_CHAMPS.map(champ => `${jour}${champ}`)], []),
    ...SERVICES.reduce((accu, service) => [...accu, ...SERVICE_EDITABLE_CHAMPS.map(champ => `${service}${champ}`)], []),
]

export const SIMPLE_OPTIMISATION_CHAMPS = [
    SEJOUR_ANNUEL,
    CONSOMMABLE_PAR_SEJOUR,
    TEMPS_LIT_SEJOUR_HEURE,
    TEMPS_IDE_SEJOUR_HEURE
];

export const SERVICE_OPTIMISATION_CHAMPS = [
    CONSOMMATION_ZONE_PETITE,
    CONSOMMATION_ZONE_MOYENNE,
    CONSOMMATION_ZONE_GRANDE,
    TEMPS_LIT_SEJOUR_HEURE,
    TEMPS_IDE_SEJOUR_HEURE,
    ...SERVICES.reduce((accu, service) => [...accu, ...SERVICE_EDITABLE_CHAMPS.map(champ => `${service}${champ}`)], []),
];

export const PLANNING_OPTIMISATION_CHAMPS = [
    CONSOMMABLE_PAR_SEJOUR,
    TEMPS_LIT_SEJOUR_HEURE,
    TEMPS_IDE_SEJOUR_HEURE,
    ...JOURS.reduce((accu, jour) => [...accu, ...PLANNING_EDITABLE_CHAMPS.map(champ => `${jour}${champ}`)], []),
];

export const PERSPECTIVE_CHAMPS = [
    SEJOUR_ANNUEL,
    CONSOMMABLE_PAR_SEJOUR,
    TEMPS_IDE_SEJOUR_HEURE,
    MONTANT_GHM,
    COEFF_GEOGRAPHIQUE,
    MONTANT_CONSOMMABLE,
    DEPENSE_MEDECIN,
    DEPENSE_IDE,
    TEMPS_IDE_SEJOUR_HEURE,
    DEPENSE_STRUCTURE,
    DEPENSE_AUTRE,
    AUTRE_RECETTE,
    TEMPS_LIT_SEJOUR_HEURE,
    ...JOURS.reduce((accu, jour) => [...accu, ...PLANNING_EDITABLE_CHAMPS.map(champ => `${jour}${champ}`)], []),
    ...SERVICES.reduce((accu, service) => [...accu, ...SERVICE_EDITABLE_CHAMPS.map(champ => `${service}${champ}`)], []),
]