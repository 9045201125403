import React, {useState} from "react";
import PropTypes from "prop-types";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FieldPropTypes from "./FieldPropTypes";
import ClassesPropTypes from "./ClassesPropTypes";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';

const getInitialValues = fields => {
    return fields.reduce(
        (accu, field) => ({...accu, [field.name]: field.defaultValue}),
        {}
    );
}

const getInitialErrors = fields => {
    return fields.reduce(
        (accu, field) => ({...accu, [field.name]: false}),
        {}
    );
}

const Form = ({name, fields, onSubmit, validator, classes, onCancel, info, disbaled}) => {
    const [values, setValues] = useState(getInitialValues(fields));
    const [errors, setErrors] = useState(getInitialErrors(fields));
    const [pending, setPending] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const {t} = useTranslation();

    const hasError = nextErrors => {
        let error = false;
        Object.keys(nextErrors).forEach(fieldName => {
            if (nextErrors[fieldName] !== false) {
                error = true;
            }
        });

        return error;
    }

    const validateFields = () => {
        const nextErrors = {...getInitialErrors(fields)};
        if (validator === null) return nextErrors;

        const checkFields = validator(values);
        Object.keys(checkFields).forEach(fieldName => {
            nextErrors[fieldName] = typeof checkFields[fieldName] === 'string'
                ? checkFields[fieldName]
                : !checkFields[fieldName]
            ;
        });

        return nextErrors;
    }

    const checkRequirement = () => {
        const nextErrors = {...getInitialErrors(fields)};
        fields.forEach(({name: fieldName, required}) => {
            if (required && !values[fieldName]) {
                nextErrors[fieldName] = t(`form.${name}.${fieldName}.required`);
            }
        });

        return nextErrors;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (pending || disbaled) return;
        setSubmitted(true);

        let nextErrors = checkRequirement();
        if (hasError(nextErrors)) {
            setErrors(nextErrors);
            return;
        }

        nextErrors = validateFields();
        if (hasError(nextErrors)) {
            setErrors(nextErrors);
            return;
        }

        nextErrors = {...getInitialErrors(fields)};
        setPending(true);
        onSubmit(values).then(() => {
            setPending(false);
            setErrors(nextErrors);
        }).catch(errors => {
            setPending(false);
            nextErrors = {
                ...nextErrors,
                ...errors
            }
            setErrors(errors);
        });
    }

    const handleChange = (event) => {
        event.preventDefault();
        if (pending) return;
        setSubmitted(false);
        setErrors(getInitialErrors(fields));
        const {value, name} = event.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleAutocompleteChange = (event, option, name) => {
        event.preventDefault();
        if (pending) return;
        setSubmitted(false);
        setErrors(getInitialErrors(fields));
        setValues({
            ...values,
            [name]: option
        });
    };

    return (
        <form
            className={classes.formContainer ? classes.formContainer : ''}
            name={name}
            onSubmit={handleSubmit}
        >
            <div className={classes.fieldsContainer ? classes.fieldsContainer : ''}>
                {fields.map(({name: fieldName, type, rows, items, disabled: fieldDisabled}) => (
                    <div
                        key={`${name}-${fieldName}`}
                        className={classes.fieldContainer ? classes.fieldContainer : ''}
                    >
                        <InputLabel
                            htmlFor={`${name}-${fieldName}`}
                            error={submitted && (!values[fieldName] || errors[fieldName] !== false)}
                            disabled={pending || fieldDisabled}
                        >
                            {t(`form.${name}.${fieldName}.label`)}
                        </InputLabel>
                        {type === 'autocomplete' ? (
                            <Autocomplete
                                id={`${name}-${fieldName}-autocomplete`}
                                options={items}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => `${option.value}` === `${value.value}`}
                                value={values[fieldName]}
                                fullWidth={true}
                                disabled={fieldDisabled}
                                onChange={(event, option) => handleAutocompleteChange(event, option, fieldName)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        id={`${name}-${fieldName}`}
                                        name={fieldName}
                                        disabled={pending || fieldDisabled}
                                        size="medium"
                                        variant="outlined"
                                        error={submitted && (!values[fieldName] || errors[fieldName] !== false)}
                                    />
                                )}
                            />
                        ) : (
                            <TextField
                                variant="outlined"
                                type={type !== 'textarea' ? type : null}
                                id={`${name}-${fieldName}`}
                                name={fieldName}
                                multiline={type === 'textarea'}
                                rows={type === 'textarea' && rows ? rows : null}
                                value={values[fieldName]}
                                onChange={handleChange}
                                select={type === 'select'}
                                disabled={pending || fieldDisabled}
                                size="medium"
                                fullWidth={true}
                                error={submitted && (!values[fieldName] || errors[fieldName] !== false)}
                            >
                                {type === 'select' && items.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <FormHelperText error={submitted && (!values[fieldName] || errors[fieldName] !== false)}>
                            <Typography variant="caption">
                                {typeof errors[fieldName] === 'string'
                                    ? errors[fieldName]
                                    : (<>&nbsp;</>)
                                }
                            </Typography>
                        </FormHelperText>
                    </div>
                ))}
                {info && (
                    <div className={classes.infoContainer ? classes.infoContainer : ''}>
                        {info}
                    </div>
                )}
            </div>
            <div className={classes.actionsContainer}>
                {onCancel !== null && (
                    <Button
                        className={classes.cancelButton}
                        variant="contained"
                        disabled={pending}
                        onClick={onCancel}
                    >
                        {t(`form.${name}.cancel`)}
                    </Button>
                )}
                <div className={classes.spaces}/>
                <Button
                    className={classes.submitButton}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={pending || disbaled}
                >
                    {t(`form.${name}.submit`)}
                </Button>
            </div>
        </form>
    );
};

Form.propTypes = {
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(FieldPropTypes).isRequired,
    onSubmit: PropTypes.func,
    validator: PropTypes.func,
    classes: ClassesPropTypes,
    onCancel: PropTypes.func,
    info: PropTypes.node,
    disbaled: PropTypes.bool
};

Form.defaultProps = {
    validator: null,
    classes: {},
    onSubmit: new Promise((resolve) => resolve()),
    onCancel: null,
    info: null,
    disbaled: false
};

export default Form;
/*


*/