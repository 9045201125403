import {useSelector} from "react-redux";
import {ROLE_ADMIN, ROLE_CLIENT, ROLE_GRT_EXPERT} from "../constants/user";

export const useIsClient = () => {
    const user = useSelector(state => state.authentication.user);

    return user && user.roles && user.roles.includes(ROLE_CLIENT);
}

export const useHasGRTExpertRole = () => {
    const user = useSelector(state => state.authentication.user);

    return user.roles && (user.roles.includes(ROLE_GRT_EXPERT) || user.roles.includes(ROLE_ADMIN));
}

export const useHasAdminRole = () => {
    const user = useSelector(state => state.authentication.user);

    return user.roles && user.roles.includes(ROLE_ADMIN);
}

export const useGetRole = () => {
    const user = useSelector(state => state.authentication.user);

    if (!user.roles) {
        return null;
    }

    return user.roles[0];
}