import {
    DEFAULT_VALUE_ADD_GHM,
    DEFAULT_VALUES_FETCH_FULFILLED, DEFAULT_VALUES_SET,
} from "../../constants/defaultValues";
import {formatNumber} from "../../managers/simulationValuesManager";

export const INITIAL_STATE = {
    annee: null,
    statut: null,
    value: null,
    error: false,
}

export default function defaultValueGHMReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {value, annee, statut} = payload;
            return {
                ...state,
                annee,
                statut,
                value,
            }
        }
        case DEFAULT_VALUES_SET: {
            const {value} = payload;
            const formatedValue = formatNumber(value, true);

            return {
                ...state,
                value: formatedValue,
                error: Number.isNaN(formatedValue) || formatedValue === 0
            }
        }
        case DEFAULT_VALUE_ADD_GHM: {
            const {value, annee, statut} = payload;
            const formatedValue = formatNumber(value, true);

            return {
                ...state,
                annee,
                statut,
                value: formatedValue,
                error: Number.isNaN(formatedValue) || formatedValue === 0
            }
        }
    }

    return state;
}