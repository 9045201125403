import {
    SIMULATION_CLEAN_SAVE_ERRORS,
    SIMULATION_FETCH_ERROR,
    SIMULATION_FETCH_FULFILLED,
    SIMULATION_FETCH_PENDING,
    SIMULATION_FETCH_REMOVE,
    SIMULATION_FETCH_REMOVE_ERROR,
    SIMULATION_FETCH_REMOVE_PENDING,
    SIMULATION_RENAME,
    SIMULATION_RENAME_ERROR,
    SIMULATION_RENAME_FULFILLED,
    SIMULATION_SAVE,
    SIMULATION_SAVE_ERROR,
    SIMULATION_SAVE_FULFILLED,
} from "../../constants/simulation";
import valuesReducer, {INITIAL_STATE as VALUES_INITIAL_STATE} from "./valuesReducer";
import optimisationReducer, {INITIAL_STATE as OPTIMISATION_INITIAL_STATE} from "./optimisationReducer";
import listEditionReducer, {INITIAL_STATE as LIST_EDITION_INITIAL_STATE} from "../edition/listEditionRecucer";
import simulationInfosReducer, {INITIAL_STATE as INFOS_INITIAL_STATE} from "./simulationInfosReducer";
import {EDITABLE_CHAMPS} from "../../constants/simulationChamps";

import {
    EDIT_VALUES_CLEAR_ERRORS,
    EDIT_VALUES_ERROR,
    EDIT_VALUES_FULFILLED,
    EDIT_VALUES_PENDING
} from "../../constants/edition";
import {OPTIMISATION_PANEL_TOGGLE} from "../../constants/optimisation";
import {ACTION_LOGOUT} from "../../constants/authentication";

export const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    error: false,
    id: null,
    infos: INFOS_INITIAL_STATE,
    values: {},
    optimisation: OPTIMISATION_INITIAL_STATE,
    edition: LIST_EDITION_INITIAL_STATE,
    pendingRemove: false,
    errorRemove: false
}

export default function simulationReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case SIMULATION_FETCH_PENDING: {
            const {id} = payload;

            return {
                ...state,
                pending: true,
                error: false,
                id
            };
        }
        case SIMULATION_FETCH_FULFILLED: {
            const {id, data} = payload;
            const {idEtab, lib, champs} = data;
            if (state.id !== id) return state;

            const values = EDITABLE_CHAMPS.reduce((accu, champ) => {
                return {
                    ...accu,
                    [champ]: valuesReducer(VALUES_INITIAL_STATE, {
                        type: SIMULATION_FETCH_FULFILLED,
                        payload: {...champs[champ]}
                    })
                }
            }, state.values);

            return {
                ...state,
                pending: false,
                fulfilled: true,
                infos: simulationInfosReducer(state.infos, {type, payload: {idEtab, lib}}),
                values
            };
        }
        case SIMULATION_FETCH_ERROR: {
            const {id} = payload;
            if (state.id !== id) return state;

            return {
                ...state,
                pending: false,
                error: true,
            };
        }
        case SIMULATION_SAVE:
        case SIMULATION_SAVE_FULFILLED:
        case SIMULATION_SAVE_ERROR: {
            const {id, saveId, champs, isBenchmarkReset} = payload;
            if (state.id !== id) return state;

            let editionType = EDIT_VALUES_PENDING;
            if (type === SIMULATION_SAVE_FULFILLED) editionType = EDIT_VALUES_FULFILLED;
            if (type === SIMULATION_SAVE_ERROR) editionType = EDIT_VALUES_ERROR;

            const edition = listEditionReducer(state.edition, {type: editionType});
            const currentSaveId = type === SIMULATION_SAVE
                ? edition.saveId
                : saveId;

            const values = champs.reduce((accu, champ) => {
                const {slug, value, isBenchmark} = champ;
                return {
                    ...accu,
                    [slug]: valuesReducer(state.values[slug], {
                        type,
                        payload: {saveId: currentSaveId, value, isBenchmark, isBenchmarkReset}
                    })
                }
            }, state.values);

            return {
                ...state,
                edition,
                values
            };
        }
        case SIMULATION_FETCH_REMOVE_PENDING: {
            return {
                ...state,
                pendingRemove: true,
                errorRemove: false,
            };
        }
        case SIMULATION_FETCH_REMOVE_ERROR: {
            return {
                ...state,
                pendingRemove: false,
                errorRemove: true,
            };
        }
        case SIMULATION_FETCH_REMOVE: {
            return {
                ...state,
                pendingRemove: false,
                errorRemove: false,
            };
        }

        case SIMULATION_CLEAN_SAVE_ERRORS: {
            const edition = listEditionReducer(state.edition, {type: EDIT_VALUES_CLEAR_ERRORS});
            const values = Object.keys(state.values).reduce((accu, slug) => {
                return {
                    ...accu,
                    [slug]: valuesReducer(state.values[slug], {type})
                }
            }, state.values);

            return {
                ...state,
                edition,
                values,
            }
        }

        case OPTIMISATION_PANEL_TOGGLE: {
            return {
                ...state,
                optimisation: optimisationReducer(state.optimisation, {type})
            }
        }
        case SIMULATION_RENAME:
        case SIMULATION_RENAME_FULFILLED:
        case SIMULATION_RENAME_ERROR: {
            const {saveId, lib} = payload;

            let editionType = EDIT_VALUES_PENDING;
            if (type === SIMULATION_RENAME_FULFILLED) editionType = EDIT_VALUES_FULFILLED;
            if (type === SIMULATION_RENAME_ERROR) editionType = EDIT_VALUES_ERROR;

            const edition = listEditionReducer(state.edition, {type: editionType});
            const currentSaveId = type === SIMULATION_RENAME
                ? edition.saveId
                : saveId;

            return {
                ...state,
                edition,
                infos: simulationInfosReducer(state.infos, {type, payload: {lib, saveId: currentSaveId}})
            }
        }
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }

    return state;
};