import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    CONSOMMATION_ZONE_GRANDE,
    CONSOMMATION_ZONE_MOYENNE,
    CONSOMMATION_ZONE_PETITE
} from "../../../../constants/simulationChamps";
import Champ from "../../../Simulation/Edition/Champ/Champ";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cn from "classnames";


const useStyles = makeStyles(theme => ({
    zoneConsommableRoot: {
        display: "flex",
        flexDirection: "row"
    },
    champContainer: {
        position: 'relative',
        width: '167px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const ZoneConsommable = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: consommationZonePetite, error: errorConsommationZonePetite} = useSelector(state => state.defaultValues.champs?.[CONSOMMATION_ZONE_PETITE]);
    const {value: consommationZoneMoyenne, error: errorConsommationZoneMoyenne} = useSelector(state => state.defaultValues.champs?.[CONSOMMATION_ZONE_MOYENNE]);
    const {value: consommationZoneGrande, error: errorConsommationZoneGrande} = useSelector(state => state.defaultValues.champs?.[CONSOMMATION_ZONE_GRANDE]);
    const errors = {
        [CONSOMMATION_ZONE_PETITE]: errorConsommationZonePetite,
        [CONSOMMATION_ZONE_MOYENNE]: errorConsommationZoneMoyenne,
        [CONSOMMATION_ZONE_GRANDE]: errorConsommationZoneGrande,
}

    const errorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.zoneConsommable')}>
            <div className={classes.zoneConsommableRoot}>
                <Champ
                    type="float"
                    size="small"
                    slug={CONSOMMATION_ZONE_PETITE}
                    className={classes.champContainer}
                    defaultValue={consommationZonePetite}
                    label={t(`simulation.champs.${CONSOMMATION_ZONE_PETITE}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Champ
                    type="float"
                    size="small"
                    slug={CONSOMMATION_ZONE_MOYENNE}
                    className={classes.champContainer}
                    defaultValue={consommationZoneMoyenne}
                    label={t(`simulation.champs.${CONSOMMATION_ZONE_MOYENNE}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Champ
                    type="float"
                    size="small"
                    slug={CONSOMMATION_ZONE_GRANDE}
                    className={classes.champContainer}
                    defaultValue={consommationZoneGrande}
                    label={t(`simulation.champs.${CONSOMMATION_ZONE_GRANDE}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
            </div>
        </Paper>
    );
};

ZoneConsommable.propTypes = {
};

ZoneConsommable.defaultProps = {
};

export default ZoneConsommable;