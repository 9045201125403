import {useLocation} from "react-router";
import qs from "qs";

const useQuery = (param = null, defaultValue = null) => {
    const location = useLocation();
    if(defaultValue !== null) defaultValue = `${defaultValue}`;

    const query = qs.parse(location.search, {ignoreQueryPrefix: true});
    if(param !== null) {
        return query[param] === undefined ? defaultValue : query[param];
    }

    return query;
};

export default useQuery;
