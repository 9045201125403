import {checkGestionDouleur} from "./simulationManager";
import {
    CONSOMMABLE_ANNUEL,
    CONSOMMABLE_PAR_SEJOUR, CONSOMMATION_ZONE_GRANDE, CONSOMMATION_ZONE_MOYENNE,
    CONSOMMATION_ZONE_PETITE, JOUR_AM_HORAIRE, JOUR_AM_HORAIRE_DEBUT, JOUR_PM_HORAIRE_DEBUT,
    SEJOUR_ANNUEL
} from "../constants/simulationChamps";
import {formatNumber} from "./simulationValuesManager";

export const checkDefaultValue = (champs, slug, value) => {
    switch (slug) {
        case SEJOUR_ANNUEL:
        case CONSOMMABLE_ANNUEL:
        case CONSOMMABLE_PAR_SEJOUR: {
            return !checkGestionDouleur(
                slug === SEJOUR_ANNUEL ? value : champs[SEJOUR_ANNUEL].value,
                slug === CONSOMMABLE_ANNUEL ? value : champs[CONSOMMABLE_ANNUEL].value,
                slug === CONSOMMABLE_PAR_SEJOUR ? value : champs[CONSOMMABLE_PAR_SEJOUR].value
            );
        }
        case CONSOMMATION_ZONE_PETITE: {
            return formatNumber(value, true) >= champs[CONSOMMATION_ZONE_MOYENNE].value
                || formatNumber(value, true) >= champs[CONSOMMATION_ZONE_GRANDE].value;
        }
        case CONSOMMATION_ZONE_MOYENNE: {
            return formatNumber(value, true) <= champs[CONSOMMATION_ZONE_PETITE].value
                || formatNumber(value, true) >= champs[CONSOMMATION_ZONE_GRANDE].value;
        }
        case CONSOMMATION_ZONE_GRANDE: {
            return formatNumber(value, true) <= champs[CONSOMMATION_ZONE_PETITE].value
                || formatNumber(value, true) <= champs[CONSOMMATION_ZONE_MOYENNE].value;
        }
        case JOUR_AM_HORAIRE_DEBUT: {
            return formatNumber(value, true) > champs[JOUR_PM_HORAIRE_DEBUT].value;
        }
        case JOUR_AM_HORAIRE: {
            return (formatNumber(value, true) + champs[JOUR_AM_HORAIRE_DEBUT].value) > champs[JOUR_PM_HORAIRE_DEBUT].value;
        }
        case JOUR_PM_HORAIRE_DEBUT: {
            return formatNumber(value, true) < (champs[JOUR_AM_HORAIRE_DEBUT].value + champs[JOUR_AM_HORAIRE].value);
        }
    }

    return false;
}