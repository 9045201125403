const FormHelperText = theme => ({
    root: {
        ...theme.typography.caption,
        color: theme.palette.grey[200],
        '&$disabled': {
            color: theme.palette.text.disabled,
        },
    }
});

export default FormHelperText;
