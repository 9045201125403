export const SIMULATION_FETCH_ERROR = 'SIMULATION_FETCH_ERROR';
export const SIMULATION_FETCH_FULFILLED = 'SIMULATION_FETCH_FULFILLED';
export const SIMULATION_FETCH_PENDING = 'SIMULATION_FETCH_PENDING';
export const SIMULATION_FETCH_REMOVE = 'SIMULATION_FETCH_REMOVE';
export const SIMULATION_FETCH_REMOVE_PENDING = 'SIMULATION_FETCH_REMOVE_PENDING';
export const SIMULATION_FETCH_REMOVE_ERROR = 'SIMULATION_FETCH_REMOVE_ERROR';
export const SIMULATION_CREATION_SUCCESS = 'SIMULATION_CREATION_SUCCESS';

export const SIMULATION_SET_CHAMPS = 'SIMULATION_SET_CHAMPS';

export const SIMULATION_SAVE_ERROR = 'SIMULATION_SAVE_ERROR';
export const SIMULATION_SAVE_FULFILLED = 'SIMULATION_SAVE_FULFILLED';
export const SIMULATION_SAVE = 'SIMULATION_SAVE';
export const SIMULATION_CLEAN_SAVE_ERRORS = 'SIMULATION_CLEAN_SAVE_ERRORS';
export const SIMULATION_RENAME = 'SIMULATION_RENAME';
export const SIMULATION_RENAME_ERROR = 'SIMULATION_RENAME_ERROR';
export const SIMULATION_RENAME_FULFILLED = 'SIMULATION_RENAME_FULFILLED';

export const SIMULATION_DELETE_ERROR = 'SIMULATION_REMOVE_ERROR';
export const SIMULATION_DELETE_FULFILLED = 'SIMULATION_REMOVE_FULFILLED';
export const SIMULATION_REMOVE_PENDING = 'SIMULATION_REMOVE_PENDING';