import {
    DEFAULT_VALUE_ADD_GHM,
    DEFAULT_VALUES_FETCH_ERROR,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_FETCH_PENDING, DEFAULT_VALUES_SAVE, DEFAULT_VALUES_SAVE_ERROR, DEFAULT_VALUES_SAVE_FULFILLED,
    DEFAULT_VALUES_SET
} from "../../constants/defaultValues";
import defaultValuesGHMReducer, {INITIAL_STATE as GHM_INITIAL_STATE} from "./defaultValuesGHMReducer";
import defaultValueReducer, {INITIAL_STATE as DEFAULT_INITIAL_STATE} from "./defaultValueReducer";
import {MONTANT_GHM} from "../../constants/simulationChamps";
import {checkDefaultValue} from "../../managers/defaultValueManager";
import {ACTION_LOGOUT} from "../../constants/authentication";

const INITIAL_STATE = {
    fetchPending: false,
    fetchFulfilled: false,
    fetchError: false,
    savePending: false,
    saveError: false,
    champs: {},
    error: false,
    hasChange: false,
}

export default function defaultValuesReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_PENDING: {
            return {
                ...state,
                fetchPending: true,
                fetchError: false,
            }
        }
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {data} = payload;
            const champs = Object.keys(data).reduce((accu, slug) => {
                const value = data[slug].value;
                if (slug !== MONTANT_GHM) {
                    accu[slug] = defaultValueReducer(DEFAULT_INITIAL_STATE, {type, payload: {slug, value}});
                } else {
                    accu[slug] = defaultValuesGHMReducer(GHM_INITIAL_STATE, {type, payload: {slug, value}})
                }
                return accu;
            }, {});

            return {
                ...state,
                fetchPending: false,
                fetchFulfilled: true,
                champs,
                hasChange: false
            }
        }
        case DEFAULT_VALUES_FETCH_ERROR: {
            return {
                ...state,
                fetchPending: false,
                fetchError: true,
            }
        }
        case DEFAULT_VALUES_SET: {
            const {index, value, slug, error: fieldError} = payload;
            const champs = {...state.champs};
            const error = checkDefaultValue(champs, slug, value) || fieldError;

            if (slug !== MONTANT_GHM) {
                champs[slug] = defaultValueReducer(champs[slug], {type, payload: {value, error, slug}});
            } else {
                champs[slug] = defaultValuesGHMReducer(champs[slug], {type, payload: {index, error, value}})
            }

            return {
                ...state,
                champs,
                error: !!Object.values(champs).find((val) => val.error === true) || error,
                hasChange: true
            };
        }
        case DEFAULT_VALUE_ADD_GHM: {
            const {slug, value, annee, statut} = payload;
            const champs = {...state.champs};

            if (slug === MONTANT_GHM) {
                champs[slug] = defaultValuesGHMReducer(champs[slug], {type, payload: {value, annee, statut}})
            }

            return {
                ...state,
                champs,
                hasChange: true
            };
        }
        case DEFAULT_VALUES_SAVE: {
            return {
                ...state,
                savePending: true,
                hasChange: false
            }
        }
        case DEFAULT_VALUES_SAVE_FULFILLED: {
            return {
                ...state,
                savePending: false,
            }
        }
        case DEFAULT_VALUES_SAVE_ERROR: {
            return {
                ...state,
                savePending: false,
                saveError: true,
            }
        }
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }
    return state;
}