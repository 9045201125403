import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import RecetteSejours from "./RecetteSejours";
import Paper from "../../../Simulation/Shared/Paper";
import ZoneConsommable from "../ZoneConsommable/ZoneConsommable";
import DepensesConsommables from "../DepensesConsommables/DepensesConsommables";
import DepensesDirectes from "../DepensesDirectes/DepensesDirectes";
const useStyles = makeStyles(theme => ({
    title: {
        textAlign: "center",
        paddingBottom: "30px"
    },
    content: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    recetteDepenseLeft: {
        flex: 1
    },
}));

const RecettesDepenses = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Typography variant="h5" className={classes.title} color="secondary">
                {t('simulation.recettesDepenses')}
            </Typography>
            <div className={classes.content}>
                <div className={classes.recetteDepenseLeft}>
                    <RecetteSejours />
                    <ZoneConsommable />
                </div>
                <div className={classes.recetteDepenseRight}>
                    <DepensesConsommables />
                    <DepensesDirectes />
                </div>
            </div>
        </>
    )
}

RecettesDepenses.propTypes = {

};

RecettesDepenses.defaultProps = {

};

export default RecettesDepenses;