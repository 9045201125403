import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    LIT_TOTAL,
    TEMPS_IDE_SEJOUR_HEURE,
    TEMPS_LIT_SEJOUR_HEURE
} from "../../../../constants/simulationChamps";
import Champ from "../../../Simulation/Edition/Champ/Champ";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CreneauHorraire from "./CreneauHorraires";


const useStyles = makeStyles(theme => ({
    gestionRessourcesRoot: {
        flex: 1
    }
}));

const GestionRessources = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: litTotal, error: errorLitTotal} = useSelector(state => state.defaultValues.champs?.[LIT_TOTAL]);
    const {value: tempsLitSejourHeure, error: errorTempsLitSejourHeure} = useSelector(state => state.defaultValues.champs?.[TEMPS_LIT_SEJOUR_HEURE]);
    const {value: tempsIdeHeure, error: errorTempsIdeHeure} = useSelector(state => state.defaultValues.champs?.[TEMPS_IDE_SEJOUR_HEURE]);

    const errorChecker = () => {
        return !(errorLitTotal || errorTempsLitSejourHeure || errorTempsIdeHeure);
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper
            title={t('simulation.gestionRessources')}
            className={classes.gestionRessourcesRoot}
        >
            <Champ
                slug={LIT_TOTAL}
                className={editionClasses.champContainer}
                defaultValue={litTotal}
                label={t(`simulation.champs.${LIT_TOTAL}.label`)}
                onBlur={handleSave}
                errorChecker={errorChecker}
            />
            <Champ
                type="float"
                slug={TEMPS_LIT_SEJOUR_HEURE}
                className={editionClasses.champContainer}
                defaultValue={tempsLitSejourHeure}
                label={t(`simulation.champs.${TEMPS_LIT_SEJOUR_HEURE}.label`)}
                helper={t(`simulation.champs.${TEMPS_LIT_SEJOUR_HEURE}.helper`)}
                onBlur={handleSave}
                hasHelper={true}
            />
            <Champ
                type="float"
                slug={TEMPS_IDE_SEJOUR_HEURE}
                className={editionClasses.champContainer}
                defaultValue={tempsIdeHeure}
                label={t(`simulation.champs.${TEMPS_IDE_SEJOUR_HEURE}.label`)}
                helper={t(`simulation.champs.${TEMPS_IDE_SEJOUR_HEURE}.helper`)}
                onBlur={handleSave}
            />
            <CreneauHorraire />
        </Paper>
    );
};

GestionRessources.propTypes = {};

GestionRessources.defaultProps = {};

export default GestionRessources;