import {
    CONSOMMABLE_ANNUEL,
    CONSOMMABLE_PAR_SEJOUR, JOUR_AM_HORAIRE, JOUR_AM_HORAIRE_DEBUT,
    JOUR_AM_LIT, JOUR_PM_HORAIRE, JOUR_PM_HORAIRE_DEBUT,
    JOUR_PM_LIT, JOURS,
    SEJOUR_ANNUEL, SERVICE_CONSOMMATION_PAR_ZONE, SERVICES
} from "../constants/simulationChamps";
import {formatNumber} from "./simulationValuesManager";
import {useSelector} from "react-redux";


export const decodeHorraire = (horraire, toString = false) => {
    const heure = Math.floor(horraire);
    const minute = Math.round((horraire - heure) * 60);

    return [heure, toString ? minute.toString().padStart(2, '0') : minute];
};

export const encodeHorraire = ([heure, minute]) => {
    return heure + (minute / 60);
};

export const horraireToString = ([heure, minute]) => {
    const strHeure = heure > 9
        ? `${heure}`
        : `0${heure}`;
    const strMinute = minute > 9
        ? `${minute}`
        : `0${minute}`;

    return `${strHeure}h${strMinute}`;
}

export const stringToHorraire = (horraire) => {
    const split = horraire.split('h');

    return [parseInt(split[0], 10), parseInt(split[1], 10)];
};

export const percentToString = percent => {
    return `${parseInt(percent * 100, 10)}%`;
}

export const stringToPercent = str => {
    let percent = str.replace(/[^0-9]/g, '');

    return parseInt(percent, 10) / 100;
}

export const checkGestionDouleur = (sejours, consommables, consommableParSejour) => {
    const formatedSejours = formatNumber(sejours);
    const formatedConsommables = formatNumber(consommables);
    const formatedConsommableParSejour = formatNumber(consommableParSejour, true);
    if(Number.isNaN(formatedSejours) || Number.isNaN(formatedConsommables) || Number.isNaN(formatedConsommableParSejour)) {
        return false;
    }
    const diff = (formatedSejours * formatedConsommableParSejour) - formatedConsommables;
    return Math.abs(diff) < 1;
}

export const computeGestionDouleur = (slug, sejours, consommables, consommableParSejour) => {
    let computedSejours = sejours;
    let computedConsommables = consommables;
    let computedConsommableParSejour = consommableParSejour;
    if (slug === SEJOUR_ANNUEL) {
        computedSejours = computedConsommables / computedConsommableParSejour;
    }
    if (slug === CONSOMMABLE_ANNUEL) {
        computedConsommables = computedConsommableParSejour * computedSejours;
    }
    if (slug === CONSOMMABLE_PAR_SEJOUR) {
        computedConsommableParSejour = computedConsommables / computedSejours;
    }

    return {
        [SEJOUR_ANNUEL]: formatNumber(computedSejours),
        [CONSOMMABLE_ANNUEL]: formatNumber(computedConsommables),
        [CONSOMMABLE_PAR_SEJOUR]: formatNumber(computedConsommableParSejour, true)
    }
}

export const useCheckLitTotal = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const lundiAmlit = useSelector(state => state.simulation.values[`lundi${JOUR_AM_LIT}`][valueProp]);
    const lundiPmlit = useSelector(state => state.simulation.values[`lundi${JOUR_PM_LIT}`][valueProp]);
    const mardiAmlit = useSelector(state => state.simulation.values[`mardi${JOUR_AM_LIT}`][valueProp]);
    const mardiPmlit = useSelector(state => state.simulation.values[`mardi${JOUR_PM_LIT}`][valueProp]);
    const mercrediAmlit = useSelector(state => state.simulation.values[`mercredi${JOUR_AM_LIT}`][valueProp]);
    const mercrediPmlit = useSelector(state => state.simulation.values[`mercredi${JOUR_PM_LIT}`][valueProp]);
    const jeudiAmlit = useSelector(state => state.simulation.values[`jeudi${JOUR_AM_LIT}`][valueProp]);
    const jeudiPmlit = useSelector(state => state.simulation.values[`jeudi${JOUR_PM_LIT}`][valueProp]);
    const vendrediAmlit = useSelector(state => state.simulation.values[`vendredi${JOUR_AM_LIT}`][valueProp]);
    const vendrediPmlit = useSelector(state => state.simulation.values[`vendredi${JOUR_PM_LIT}`][valueProp]);
    const samediAmlit = useSelector(state => state.simulation.values[`samedi${JOUR_AM_LIT}`][valueProp]);
    const samediPmlit = useSelector(state => state.simulation.values[`samedi${JOUR_PM_LIT}`][valueProp]);
    const dimancheAmlit = useSelector(state => state.simulation.values[`dimanche${JOUR_AM_LIT}`][valueProp]);
    const dimanchePmlit = useSelector(state => state.simulation.values[`dimanche${JOUR_PM_LIT}`][valueProp]);

    return (value) => {
        const formatedValue = formatNumber(value);
        if(Number.isNaN(formatedValue)) return false;

        return (
            lundiAmlit <= formatedValue &&
            lundiPmlit <= formatedValue &&
            mardiAmlit <= formatedValue &&
            mardiPmlit <= formatedValue &&
            mercrediAmlit <= formatedValue &&
            mercrediPmlit <= formatedValue &&
            jeudiAmlit <= formatedValue &&
            jeudiPmlit <= formatedValue &&
            vendrediAmlit <= formatedValue &&
            vendrediPmlit <= formatedValue &&
            samediAmlit <= formatedValue &&
            samediPmlit <= formatedValue &&
            dimancheAmlit <= formatedValue &&
            dimanchePmlit <= formatedValue
        );
    };
}

export const checkLitJour = (litJour, litTotal) => {
    const formatedValue = formatNumber(litJour);
    if(Number.isNaN(formatedValue)) return false;

    return formatedValue <= litTotal;
}

export const useGetDuplicateJourValues = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return jour => {
        const amHoraireToDuplicate = values[`${jour}${JOUR_AM_HORAIRE}`][valueProp];
        const pmHoraireToDuplicate = values[`${jour}${JOUR_PM_HORAIRE}`][valueProp];
        const amHoraireDebutToDuplicate = values[`${jour}${JOUR_AM_HORAIRE_DEBUT}`][valueProp];
        const pmHoraireDebutToDuplicate = values[`${jour}${JOUR_PM_HORAIRE_DEBUT}`][valueProp];
        const amlitToDuplicate = values[`${jour}${JOUR_AM_LIT}`][valueProp];
        const pmlitToDuplicate = values[`${jour}${JOUR_PM_LIT}`][valueProp];
        const jourValues = {};
        JOURS.forEach((currentJour) => {
            const lit = values[`${currentJour}${JOUR_AM_LIT}`][valueProp];
            if(jour !== currentJour && lit !== 0) {
                jourValues[currentJour] = [];
                jourValues[currentJour].push({slug: `${currentJour}${JOUR_AM_HORAIRE}`, value: amHoraireToDuplicate, isBenchmark, isFloat: true});
                jourValues[currentJour].push({slug: `${currentJour}${JOUR_PM_HORAIRE}`, value: pmHoraireToDuplicate, isBenchmark, isFloat: true});
                jourValues[currentJour].push({slug: `${currentJour}${JOUR_AM_HORAIRE_DEBUT}`, value: amHoraireDebutToDuplicate, isBenchmark, isFloat: true});
                jourValues[currentJour].push({slug: `${currentJour}${JOUR_PM_HORAIRE_DEBUT}`, value: pmHoraireDebutToDuplicate, isBenchmark, isFloat: true});
                jourValues[currentJour].push({slug: `${currentJour}${JOUR_AM_LIT}`, value: amlitToDuplicate, isBenchmark, isFloat: false});
                jourValues[currentJour].push({slug: `${currentJour}${JOUR_PM_LIT}`, value: pmlitToDuplicate, isBenchmark, isFloat: false});
            }
        });

        return jourValues;
    }
}

export const useGetNextConsommationParZoneValues = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return (prevValue, nextValue) => {
          const nextValues = [];
          SERVICES.forEach(service => {
              const consommationParZone = values[`${service}${SERVICE_CONSOMMATION_PAR_ZONE}`][valueProp];
              if(consommationParZone === prevValue) {
                  nextValues.push({
                      slug: `${service}${SERVICE_CONSOMMATION_PAR_ZONE}`,
                      value: nextValue,
                      isBenchmark,
                      isFloat: true
                  });
              }
          });

          return nextValues;
    };
}
