import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import colors from './colors';
import Typography from "./Typography";
import {darken, fade} from "@material-ui/core/styles/colorManipulator";
import Button from "./overrides/Button";
import FormLabel from "./overrides/FormLabel";
import FormHelperText from "./overrides/FormHelperText";
import InputBase from "./overrides/InputBase";
import OutlinedInput from "./overrides/OutlinedInput";
import Checkbox from "./overrides/Checkbox";
import ToggleButton from "./overrides/ToggleButton";
import Container from "./overrides/Container";
import Dialog from "./overrides/Dialog";
import DialogTitle from "./overrides/DialogTitle";
import DialogContent from "./overrides/DialogContent";
import DialogActions from "./overrides/DialogActions";
import Popover from "./overrides/Popover";
import Tooltip from "./overrides/Tooltip";
import Autocomplete from "./overrides/Autocomplete";

const theme = createMuiTheme({
    palette: {
        text: {
            primary: colors.charcoal,
            secondary: colors.petrol,
            contrastText: '#fff',
            disabled: colors.silver,
            secondaryHover: darken(colors.petrol, 0.15),
        },
        primary: {
            main: colors.sunflowerYellow,
            dark: darken(colors.sunflowerYellow, 0.1),
        },
        secondary: {
            main: colors.petrol,
            dark: darken(colors.petrol, 0.15)
        },
        error: {
            main: colors.tangerine,
            dark: darken(colors.tangerine, 0.1),
        },
        grey: {
            50: colors.paleGrey,
            100: colors.lightGrey,
            200: colors.silver,
            300: colors.coolGrey,
            400: colors.blueGrey,
            500: colors.charcoal,
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
        },
        divider: colors.lightGrey,
        background: {
            default: '#fff',
            contrast: colors.petrol,
            gradient: `linear-gradient(225deg, #37929C 0%, ${colors.petrol} 100%)`
        },
        resultat: {
            value: {
                color: colors.turquoiseBlue
            },
            public: {
                color: colors.turquoiseBlue
            },
            private: {
                color: colors.charcoal
            },
            graph: {
                recette: {
                    bar: colors.turquoiseBlue,
                    label: fade(colors.turquoiseBlue, 0.5)
                },
                consommable: {
                    bar: colors.sunflowerYellow,
                    label: fade(colors.sunflowerYellow, 0.5)
                },
                depense: {
                    bar: colors.tangerine,
                    label: fade(colors.tangerine, 0.5)
                },
                marge: {
                    bar: colors.nastyGreen,
                    label: fade(colors.nastyGreen, 0.5),
                    bg: fade(colors.nastyGreen, 0.4),
                    perspectiveBg: fade(colors.nastyGreen, 0.2),
                },
                negative: {
                    bar: colors.cerise,
                    label: fade(colors.cerise, 0.5),
                    bg: fade(colors.cerise, 0.4),
                    perspectiveBg: fade(colors.cerise, 0.2),
                }
            },
            planning: {
                chipValue: colors.nastyGreen
            }
        },
        carte: {
            legende: {
                boxShadow: colors.lightGreyThree
            }
        }
    },
    typography: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        htmlFontSize: 16,
        fontWeight: 400,
    },
    props: {
        MuiButton: {
            disableElevation: true
        },
        MuiSelect: {
            MenuProps: {
                elevation: 1
            }
        },
        MuiDialogTitle: {
            disableTypography: true
        }
    }
});

theme.typography = {
    ...theme.typography,
    ...Typography(theme)
};

theme.overrides = {
    MuiContainer: Container(theme),
    MuiButton: Button(theme),
    MuiFormLabel: FormLabel(theme),
    MuiFormHelperText: FormHelperText(theme),
    MuiInputBase: InputBase(theme),
    MuiOutlinedInput: OutlinedInput(theme),
    MuiRadio: Checkbox(theme),
    MuiCheckbox: Checkbox(theme),
    MuiToggleButton: ToggleButton(theme),
    MuiDialog: Dialog(theme),
    MuiDialogTitle: DialogTitle(theme),
    MuiDialogContent: DialogContent(theme),
    MuiDialogActions: DialogActions(theme),
    MuiPopover: Popover(theme),
    MuiTooltip: Tooltip(theme),
    MuiAutocomplete: Autocomplete(theme),
};

export default theme;
