import {STATIC_DATA_FETCH_FULFILLED} from "../../constants/staticData";

export const INITIAL_STATE = {
    id: null,
    idReg: null,
    code: null,
    lib: null,
    dnp: null,
    patients: null,
    geometry: null,
    coeffGhm: 1
};

export default function departementReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case STATIC_DATA_FETCH_FULFILLED : {
            const {id, idReg, code, lib, dnp, patients, geometry, coeffGHM} = payload;
            return {...state, id, idReg, code, lib, dnp, patients, geometry, coeffGhm: coeffGHM};
        }
    }
    return state;
};