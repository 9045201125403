const Popover = theme => ({
    root: {
        '& .MuiDialogContent-root': {
            padding: '23px 32px 22px 32px',
            '&:first-child': {
                padding: '23px 32px 22px 32px',
            }
        }
    },
    paper: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 20px 0 rgba(0,90,105,0.1)'
    }
});

export default Popover;