import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import Champ from "../Champ/Champ";
import {useTranslation} from "react-i18next";
import {
    decodeHorraire,
    encodeHorraire,
    horraireToString,
    stringToHorraire
} from "../../../../managers/simulationManager";
import {JOUR_AM_HORAIRE, JOUR_PM_HORAIRE} from "../../../../constants/simulationChamps";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    PlanningHorrairesRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    champContainer: {
        width: '80px',
        flexBasis: '80px',
    },
    firstLib: {
        display: 'block',
        width: '60px',
        flexBasis: '60px',
        textAlign: 'right',
        paddingRight: '16px',
    },
    secondLib: {
        display: 'block',
        paddingRight: '16px',
        paddingLeft: '16px'
    },
    pm: {
        marginTop: '18px'
    }
}));

const PlanningHorraires = ({
                               jour,
                               nbHeure,
                               horraireDebut,
                               demiJournee,
                               onChange,
                               errorChecker,
                               showFirstLib,
                               helper
                           }) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const slugPrefix = demiJournee === 'am'
        ? `${jour}${JOUR_AM_HORAIRE}`
        : `${jour}${JOUR_PM_HORAIRE}`
    ;
    const debut = decodeHorraire(horraireDebut);
    const fin = decodeHorraire(horraireDebut + nbHeure);

    const handleSave = (slug, value) => {
        const isDebut = slug.indexOf('debut') !== -1;
        const nextFin = isDebut
            ? fin
            : stringToHorraire(value);
        const nextDebut = isDebut
            ? stringToHorraire(value)
            : debut;
        const nextHorraireDebut = encodeHorraire(nextDebut);
        const nextHorraireFin = encodeHorraire(nextFin);
        const nextNbHeure = encodeHorraire(nextFin) - nextHorraireDebut;

        onChange(demiJournee, nextHorraireDebut, nextNbHeure, nextHorraireFin);
    }

    return (
        <div className={cn(classes.PlanningHorrairesRoot, {[classes.pm]: demiJournee === 'pm'})}>
            {showFirstLib && (
                <span className={classes.firstLib}>
                    {demiJournee === 'am'
                        ? t('simulation.planningTable.de')
                        : t('simulation.planningTable.etde')
                    }
                </span>
            )}
            <div className={classes.champContainer}>
                <Champ
                    type="time"
                    slug={`${slugPrefix}-debut`}
                    defaultValue={horraireToString(debut)}
                    size="small"
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                    noHelper={true}
                />
            </div>
            <span className={classes.secondLib}>
                {t('simulation.planningTable.a')}
            </span>
            <div className={classes.champContainer}>
                <Champ
                    type="time"
                    slug={`${slugPrefix}-fin`}
                    defaultValue={horraireToString(fin)}
                    size="small"
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                    noHelper={true}
                />
            </div>
        </div>
    );
};

PlanningHorraires.propTypes = {
    jour: PropTypes.string.isRequired,
    demiJournee: PropTypes.oneOf(['am', 'pm']),
    nbHeure: PropTypes.number.isRequired,
    horraireDebut: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    errorChecker: PropTypes.func,
    showFirstLib: PropTypes.bool,
    helper: PropTypes.string
};

PlanningHorraires.defaultProps = {
    demiJournee: 'am',
    onChange: () => {
    },
    errorChecker: () => true,
    showFirstLib: true
};

export default PlanningHorraires;