import { combineReducers } from 'redux';
import authenticationReducer from "./authentication/authenticationReducer";
import staticDataReducer from "./staticData/staticDataReducer";
import simulationsListReducer from "./carte/simulationsListReducer";
import mapReducer from "./carte/mapReducer";
import simulationReducer from "./simulation/simulationReducer";
import etabReducer from "./carte/etabReducer";
import searchReducer from "./carte/searchReducer";
import defaultValuesReducer from "./defaultValues/defaultValuesReducer";
import perspectiveReducer from "./perspective/perspectiveReducer";

export default () => {
    const rootReducer = {
        authentication: authenticationReducer,
        staticData: staticDataReducer,
        carte: combineReducers({
            simulations: simulationsListReducer,
            map: mapReducer,
            etab: etabReducer,
            search: searchReducer
        }),
        simulation: simulationReducer,
        defaultValues: defaultValuesReducer,
        perspective: perspectiveReducer
    };

    return combineReducers(rootReducer);
};