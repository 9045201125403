const Typography = theme => ({
    body1: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18.2),
        fontWeight: 'normal',
        letterSpacing: '0.08px',
    },
    body2: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(15.6),
        fontWeight: 'bold',
        letterSpacing: '0.09px',
    },
    subtitle1: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(15.6),
        fontWeight: 'normal',
        letterSpacing: '0.06px',
    },
    h4: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(31.2),
        fontWeight: 'bold',
        letterSpacing: '0.05px',
    },
    h5: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(26),
        fontWeight: 'bold',
        letterSpacing: '0px',
    },
    h6: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20.8),
        fontWeight: 'bold',
        letterSpacing: '0.06px',
    },
    caption: {
        fontFamily: "Visuelt Pro, Arial, sans-serif",
        fontSize: theme.typography.pxToRem(10),
        lineHeight: theme.typography.pxToRem(13),
        fontWeight: 'bold',
        letterSpacing: '0.25px',
    }
});

export default Typography;