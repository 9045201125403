import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import {
    JOUR_AM_HORAIRE,
    JOUR_PM_HORAIRE,
    JOUR_AM_HORAIRE_DEBUT,
    JOUR_PM_HORAIRE_DEBUT
} from "../../../../constants/simulationChamps";
import PlanningHorraires from "../../../Simulation/Edition/Planning/PlanningHorraires";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
    PlanningRowRoot: {
    },
    margin: {
        marginBottom: "10px"
    },
    caption: {
        color: theme.palette.text.secondary
    }
}));

const CreneauHorraire = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const {value: amHoraireDebut, error: errorAmHoraireDebut} = useSelector(state => state.defaultValues.champs?.[JOUR_AM_HORAIRE_DEBUT]);
    const {value: amHoraire, error: errorAmHoraire} = useSelector(state => state.defaultValues.champs?.[JOUR_AM_HORAIRE]);
    const {value: pmHoraireDebut, error: errorPmHoraireDebut} = useSelector(state => state.defaultValues.champs?.[JOUR_PM_HORAIRE_DEBUT]);
    const {value: pmHoraire, error: errorPmHoraire} = useSelector(state => state.defaultValues.champs?.[JOUR_PM_HORAIRE]);
    const errors = {
        [JOUR_AM_HORAIRE_DEBUT]: errorAmHoraireDebut,
        [`${JOUR_AM_HORAIRE}-fin`]: errorAmHoraire,
        [JOUR_PM_HORAIRE_DEBUT]: errorPmHoraireDebut,
        [`${JOUR_PM_HORAIRE}-fin`]: errorPmHoraire,
    }

    const horraireErrorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSaveHorraire = (demiJournee, horraireDebut, horraire, horraireFin) => {
        const slugHorraireDebut = demiJournee === 'am'
            ? JOUR_AM_HORAIRE_DEBUT
            : JOUR_PM_HORAIRE_DEBUT
        ;
        const slugHorraire = demiJournee === 'am'
            ? JOUR_AM_HORAIRE
            : JOUR_PM_HORAIRE
        ;


        dispatch(setValue(slugHorraireDebut, horraireDebut));
        dispatch(setValue(slugHorraire, horraire, null, horraireFin < horraireDebut));
    }

    return (
        <div className={editionClasses.champContainer}>
            <div className={classes.margin}>
                <InputLabel
                    htmlFor={JOUR_AM_HORAIRE_DEBUT}
                    error={!horraireErrorChecker(JOUR_AM_HORAIRE_DEBUT) || !horraireErrorChecker(`${JOUR_AM_HORAIRE}-fin`) }
                >
                    {t('administration.champs.creneau.matin')}
                </InputLabel>
                <PlanningHorraires
                    jour=""
                    nbHeure={amHoraire}
                    horraireDebut={amHoraireDebut}
                    onChange={handleSaveHorraire}
                    errorChecker={horraireErrorChecker}
                    showFirstLib={false}
                />
                <Typography variant="subtitle1" className={classes.caption}>{t('administration.champs.creneau.helper')}</Typography>
            </div>
            <div>
                <InputLabel
                    htmlFor={JOUR_PM_HORAIRE_DEBUT}
                    error={!horraireErrorChecker(JOUR_PM_HORAIRE_DEBUT) || !horraireErrorChecker(`${JOUR_PM_HORAIRE}-fin`) }
                >
                    {t('administration.champs.creneau.apresMidi')}
                </InputLabel>
                <PlanningHorraires
                    jour=""
                    demiJournee="pm"
                    nbHeure={pmHoraire}
                    horraireDebut={pmHoraireDebut}
                    onChange={handleSaveHorraire}
                    errorChecker={horraireErrorChecker}
                    showFirstLib={false}
                />
                <Typography variant="subtitle1" className={classes.caption}>{t('administration.champs.creneau.helper')}</Typography>
            </div>
        </div>
    );
};

CreneauHorraire.propTypes = {
};

CreneauHorraire.defaultProps = {
};

export default CreneauHorraire;