import React from "react";
import RecettesDepenses from "./RecettesDepenses/RecettesDepenses";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import GestionDouleur from "./GestionDouleur/GestionDouleur";
import GestionRessources from "./GestionRessources/GestionRessources";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
    DefaultValueDialogContentRoot: {
        paddingTop: 0
    },
    error: {
        textAlign: 'center',
        paddingBottom: '16px'
    },
    row: {
        display: "flex"
    }
}));

const DefaultValueDialogContent = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const error = useSelector(state => state.defaultValues.error);
    const pending = useSelector(state => state.defaultValues.fetchPending);

    if (pending) return null;
    return (
        <DialogContent className={classes.DefaultValueDialogContentRoot}>
            <Typography className={classes.error} variant="h6" color="error">
                {error ? t('administration.dialog.error') : <>&nbsp;</>}
            </Typography>
            <div className={classes.row}>
                <GestionDouleur />
                <GestionRessources />
            </div>
            <RecettesDepenses />
        </DialogContent>
    );
}

DefaultValueDialogContent.propTypes = {
};

DefaultValueDialogContent.defaultProps = {
};

export default DefaultValueDialogContent;