const Autocomplete = theme => ({
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            '& .MuiAutocomplete-input:first-child': {
                padding: '13px 0 13px 13px',
            }
        }
    }
});

export default Autocomplete;