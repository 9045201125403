import {
    DEFAULT_VALUE_ADD_GHM,
    DEFAULT_VALUES_FETCH_FULFILLED, DEFAULT_VALUES_SET,
} from "../../constants/defaultValues";
import defaultValueGHMReducer, {INITIAL_STATE as GHM_INITIAL_STATE} from "./defaultValueGHMReducer";
import {arrayAdd, arrayUpdate} from "../../utils/immutableArray";

export const INITIAL_STATE = {
    slug: null,
    value: [],
    error: false
}

export default function defaultValuesGHMReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED: {
            const {slug, value} = payload;

            return {
                ...state,
                slug,
                value: value.map(({annee, statut, value}) => defaultValueGHMReducer(GHM_INITIAL_STATE, {type, payload: {annee, statut, value}}))
            }
        }
        case DEFAULT_VALUES_SET: {
            const {index, value, error} = payload;
            const newValue = arrayUpdate(state.value, index, defaultValueGHMReducer(state.value[index], {type, payload: {value}}));

            return {
                ...state,
                value: newValue,
                error: error || !!newValue.find((val) => val.error === true)
            }
        }
        case DEFAULT_VALUE_ADD_GHM: {
            const {value, annee, statut} = payload;
            const newValue = arrayAdd(state.value, 0, defaultValueGHMReducer(GHM_INITIAL_STATE, {type, payload: {value, annee, statut}}));

            return {
                ...state,
                value: newValue,
            }
        }
    }
    return state;
}