const FormLabel = theme => ({
    root: {
        ...theme.typography.body,
        color: theme.palette.text.primary,
        marginBottom: '4px',
        '&$disabled': {
            color: theme.palette.text.disabled,
        },
        '&$error': {
            color: theme.palette.text.primary,
        }
    }
});

export default FormLabel;
