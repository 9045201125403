import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    MONTANT_CONSOMMABLE
} from "../../../../constants/simulationChamps";
import Champ from "../../../Simulation/Edition/Champ/Champ";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cn from "classnames";


const useStyles = makeStyles(theme => ({
    zoneConsommableRoot: {
        display: "flex",
        flexDirection: "row"
    }
}));

const DepensesConsommables = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: montantConsommable, error: errorMontantConsommable} = useSelector(state => state.defaultValues.champs?.[MONTANT_CONSOMMABLE]);


    const errorChecker = () => {
        return !errorMontantConsommable;
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.depensesConsommables')}>
            <div className={classes.zoneConsommableRoot}>
                <Champ
                    type="currency"
                    slug={MONTANT_CONSOMMABLE}
                    className={editionClasses.champContainer}
                    defaultValue={montantConsommable}
                    label={t(`simulation.champs.${MONTANT_CONSOMMABLE}.label`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
            </div>
        </Paper>
    );
};

DepensesConsommables.propTypes = {
};

DepensesConsommables.defaultProps = {
};

export default DepensesConsommables;