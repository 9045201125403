import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../actions/user/userAction";
import {USER_SHARED_INFORMATIONS} from "../../constants/user";
import Button from "@material-ui/core/Button";
import {useIsClient} from "../../managers/userManager";

const useStyles = makeStyles(theme => ({
    sharedInformationsRoot: {
        position: 'fixed',
        height: '64px',
        width: "auto",
        backgroundColor: theme.palette.secondary.main,
        opacity: "0.9",
        borderRadius: "8px",
        bottom: "14px",
        left: "22px",
        right: "22px",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
    },
    text: {
        color: theme.palette.text.contrastText,
        paddingLeft: "28px"
    },
    actions: {
        flex: 1,
        textAlign: "right"
    },
    buttons: {
        marginRight: "16px",
    }
}));

const SharedInformation = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const user = useSelector(state => state.authentication.user);
    const isClient = useIsClient();
    const dispatch = useDispatch();

    if (user.sharedInformations !== null || !isClient) return null;

    const refuseSharedInformations = () => {
        dispatch(updateUser(USER_SHARED_INFORMATIONS, false));
    }

    const acceptSharedInformations = () => {
        dispatch(updateUser(USER_SHARED_INFORMATIONS, true));
    }

    return (
        <div className={classes.sharedInformationsRoot}>
            <Typography className={classes.text}>{t('footer.sharedInformationRoot')}</Typography>
            <div className={classes.actions}>
                <Button variant="contained" size="small" onClick={acceptSharedInformations} className={classes.buttons}>
                    {t('footer.accept')}
                </Button>
                <Button variant="contained" size="small" color="primary" onClick={refuseSharedInformations}  className={classes.buttons}>
                    {t('footer.refuse')}
                </Button>
            </div>
        </div>
    )
}

SharedInformation.propTypes = {
};

SharedInformation.defaultProps = {
};

export default SharedInformation;