import {ACTION_CHANGE_ETAB_LIST} from "../../constants/carte";
import {ETABLISSEMENT_EDIT_IS_CLIENT_FULFILLED} from "../../constants/staticData";

const INITIAL_STATE = {
    typeGeo: null,
    status: null,
    isClient: null,
    isSdc: null,
    champ: null,
    min: 0,
    max: 0,
    departements: {},
    regions: {},
    france: {},
    totalRegion: 0,
    totalDepartement: 0,
    totalFrance: 0,
};

export default function etabReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case ETABLISSEMENT_EDIT_IS_CLIENT_FULFILLED: {
            const { etablissement } = payload;
            if (! state.isClient) {
                return state;
            }
            if (etablissement.isClient) {
                return {
                    ...state,
                    regions: {
                        ...state.regions,
                        [etablissement.idReg]: [
                            ...state.regions[etablissement.idReg],
                            etablissement
                        ]
                    },
                    departements: {
                        ...state.departements,
                        [etablissement.idDep]: [
                            ...state.departements[etablissement.idDep],
                            etablissement
                        ]
                    },
                    france: {
                        ...state.france,
                        [etablissement.id]: etablissement
                    },
                    totalRegion: state.totalRegion + 1,
                    totalDepartement: state.totalDepartement + 1,
                    totalFrance: state.totalFrance + 1
                };
            }

            const { [etablissement.id]: _, ...france } = state.france;
            return {
                ...state,
                regions: {
                    ...state.regions,
                    [etablissement.idReg]: state.regions[etablissement.idReg].filter(e => e.id !== etablissement.id)
                },
                departements: {
                    ...state.departements,
                    [etablissement.idDep]: state.departements[etablissement.idDep].filter(e => e.id !== etablissement.id)
                },
                france,
                totalRegion: state.totalRegion > 0 ? state.totalRegion - 1 : state.totalRegion,
                totalDepartement: state.totalDepartement > 0 ? state.totalDepartement - 1 : state.totalDepartement,
                totalFrance: state.totalFrance > 0 ? state.totalFrance - 1 : state.totalFrance
            };
        }
        case ACTION_CHANGE_ETAB_LIST: {
            return {
                ...state,
                ...payload
            };
        }
    }

    return state;
}