import {
    CARTE_PAGE, HOME_PAGE, INIT_PASSWORD_PAGE,
    LOGIN_PAGE, MATERIAL_DEMO_PAGE, RESET_PASSWORD_PAGE, RESULTAT_PAGE, SIMULATION_PAGE,
} from "../../constants/router";

export default {
    [HOME_PAGE]: '/',
    [CARTE_PAGE]: '/carte',
    [LOGIN_PAGE]: '/login',
    [RESET_PASSWORD_PAGE]: '/resetPassword/:resetToken',
    [INIT_PASSWORD_PAGE]: '/initPassword/:resetToken',
    [SIMULATION_PAGE]: '/simulation/:id/edition',
    [RESULTAT_PAGE]: '/simulation/:id/resultat',
    [MATERIAL_DEMO_PAGE]: '/theme-demo',
}