import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    CONSOMMABLE_ANNUEL,
    CONSOMMABLE_PAR_SEJOUR,
    SEJOUR_ANNUEL
} from "../../../../constants/simulationChamps";
import TimelineIcon from '@material-ui/icons/Timeline';
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Champ from "../../../Simulation/Edition/Champ/Champ";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import {computeGestionDouleur} from "../../../../managers/simulationManager";
import Paper from "../../../Simulation/Shared/Paper";

const useStyles = makeStyles(theme => ({
    errorAction: {
        display: 'block',
        position: 'absolute',
        right: '-10px',
        top: '50%',
        transform: 'translate(100%, -50%)'
    },
    gestionDouleurRoot: {
        flex: 1
    }
}));


const GestionDouleur = ({isBenchmark}) => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const {value: sejours, error: errorSejours} = useSelector(state => state.defaultValues.champs?.[SEJOUR_ANNUEL]);
    const {value: consommables, error: errorConsommables} = useSelector(state => state.defaultValues.champs?.[CONSOMMABLE_ANNUEL]);
    const {value: consommableParSejour, error: errorConsommableParSejour} = useSelector(state => state.defaultValues.champs?.[CONSOMMABLE_PAR_SEJOUR]);

    const dispatch = useDispatch();
    const [computeKey, setComputeKey] = useState(null);

    const errorChecker = () => {
        return !(errorSejours || errorConsommables || errorConsommableParSejour);
    };

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    const handleComputeValue = slug => {
        const computedValues = computeGestionDouleur(slug, sejours, consommables, consommableParSejour);
        handleSave(slug, computedValues[slug]);
        setComputeKey(computeKey === slug ? null : slug);
    }

    const renderErrorAction = (slug, value) => {
        const check = errorChecker(slug, value);
        if(check) return null;

        return (
            <div className={classes.errorAction}>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleComputeValue(slug)}
                    endIcon={<TimelineIcon />}
                >
                    {t('simulation.corriger')}
                </Button>
            </div>
        );
    };

    return (
        <Paper
            title={t('simulation.gestionDouleur')}
            className={classes.gestionDouleurRoot}
        >
            <div className={editionClasses.champContainer}>
                <Champ
                    key={computeKey === SEJOUR_ANNUEL ? computeKey : undefined}
                    slug={SEJOUR_ANNUEL}
                    defaultValue={sejours}
                    onBlur={handleSave}
                    label={t(`simulation.champs.${SEJOUR_ANNUEL}.label`)}
                    errorChecker={errorChecker}
                />
                {renderErrorAction(SEJOUR_ANNUEL, sejours)}
            </div>
            <div className={editionClasses.champContainer}>
                <Champ
                    key={computeKey === CONSOMMABLE_ANNUEL ? computeKey : undefined}
                    slug={CONSOMMABLE_ANNUEL}
                    defaultValue={consommables}
                    onBlur={handleSave}
                    label={t(`simulation.champs.${CONSOMMABLE_ANNUEL}.label`)}
                    helper={t(`simulation.champs.${CONSOMMABLE_ANNUEL}.helper`)}
                    errorChecker={errorChecker}
                />
                {renderErrorAction(CONSOMMABLE_ANNUEL, consommables)}
            </div>
            <div className={editionClasses.champContainer}>
                <Champ
                    key={computeKey === CONSOMMABLE_PAR_SEJOUR ? computeKey : undefined}
                    type="float"
                    slug={CONSOMMABLE_PAR_SEJOUR}
                    defaultValue={consommableParSejour}
                    onBlur={handleSave}
                    label={t(`simulation.champs.${CONSOMMABLE_PAR_SEJOUR}.label`)}
                    errorChecker={errorChecker}
                />
                {renderErrorAction(CONSOMMABLE_PAR_SEJOUR, consommableParSejour)}
            </div>
        </Paper>
    );
};

GestionDouleur.propTypes = {
    isBenchmark: PropTypes.bool
};

GestionDouleur.defaultProps = {
    isBenchmark: false
};

export default GestionDouleur;