import PropTypes from "prop-types";

export default PropTypes.exact({
    name: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    rows: PropTypes.number,
    required: PropTypes.bool,
    items: PropTypes.array,
    disabled: PropTypes.bool,
});