const InputBase = theme => ({
    root: {
        backgroundColor: '#fff',
        color: theme.palette.text.main,
        '& .MuiSelect-icon': {
            color: theme.palette.text.secondary,
        },
        '& .MuiInputAdornment-root': {
            color: theme.palette.text.secondary,
        },
        '&$error .MuiSelect-icon': {
            color: theme.palette.error.main,
        },
        '&$error .MuiInputAdornment-root': {
            color: theme.palette.error.main,
        }
    },
    error: {
        color: theme.palette.error.main,
    },
    disabled: {
        '& .MuiInputAdornment-root': {
            color: theme.palette.text.disabled,
        },
    },
    input: {
        '&:focus': {
            outline: 0,
        },
        '&:-webkit-autofill': {
            "-webkit-box-shadow": '0 0 0 30px white inset !important'
        },
        '&:-webkit-autofill:hover': {
            "-webkit-box-shadow": '0 0 0 30px white inset !important'
        },
        '&:-webkit-autofill:focus': {
            "-webkit-box-shadow": '0 0 0 30px white inset !important'
        },
        '&:-webkit-autofill:active': {
            "-webkit-box-shadow": '0 0 0 30px white inset !important'
        },
        '&::-webkit-input-placeholder': {
            color: theme.palette.grey[300],
            opacity: 1
        },
        '&::-moz-placeholder': {
            color: theme.palette.grey[300],
            opacity: 1
        },
        '&:-ms-input-placeholder': {
            color: theme.palette.grey[300],
            opacity: 1
        },
        '&:-moz-placeholder': {
            color: theme.palette.grey[300],
            opacity: 1
        },
    }
});

export default InputBase;