/*
 * All polyfills
 * TODO : supprimer cette ligne et remplacer useBuiltIns:entry par useBuiltIns:usage (Malheuresement cela ne semble pas fonctionner à cause des librairies tierces qui n'ont pas elles même de polyfills)
 */
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import configureStore from "./store/configureStore";

import './assets/fonts/fonts.css';

const store = configureStore();

ReactDOM.render(
    <App store={store} />,
    document.getElementById('app')
);