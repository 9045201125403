import PropTypes from "prop-types";

export default PropTypes.exact({
    formContainer: PropTypes.string,
    fieldsContainer: PropTypes.string,
    fieldContainer: PropTypes.string,
    actionsContainer: PropTypes.string,
    submitButton: PropTypes.string,
    spaces: PropTypes.string,
    cancelButton: PropTypes.string,
    infoContainer: PropTypes.string
});