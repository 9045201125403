import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ContrastedTextButton from "../../Material/ContrastedTextButton";
import {useDispatch, useSelector} from "react-redux";
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MonProfil from "../Administration/MonProfil/MonProfil";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogCloseButton from "../../Material/theme/DialogCloseButton";
import {updateUser} from "../../actions/user/userAction";

const useStyles = makeStyles(theme => ({
    monProfilDialog: {
        borderRadius: "24px"
    },
    btn: {
        textTransform: 'none'
    }
}));

const HeaderMonProfil = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const user = useSelector(state => state.authentication.user);

    const handleClick = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false)
    }

    return (
        <>
            <ContrastedTextButton
                startIcon={<FaceOutlinedIcon />}
                onClick={handleClick}
                size="large"
                className={classes.btn}
            >
                {user.username}
            </ContrastedTextButton>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="administration-mon-profil"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogCloseButton handleClose={handleCancel} />
                <DialogTitle id="administration-mon-profil">
                    <Typography variant="h4">
                        {t('administration.monProfil.title')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MonProfil handleCancel={handleCancel} />
                </DialogContent>
            </Dialog>
        </>
    );
}


HeaderMonProfil.propTypes = {

};

HeaderMonProfil.defaultProps = {

};

export default HeaderMonProfil;