import sizes from "../sizes";

const DialogTitle = theme => ({
    root: {
        padding: `${sizes.dialogTopPadding}px ${sizes.dialogHPadding}px 0 ${sizes.dialogHPadding}px`,
        textAlign: 'center',
        textTransform: 'uppercase',
        '& > :last-child': {
            paddingBottom: `${sizes.dialogInnerVPadding}px`,
            borderBottom: `solid 1px ${theme.palette.divider}`,
        }
    }
});

export default DialogTitle;