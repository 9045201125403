import {
    EDIT_VALUES_CLEAR_ERROR, EDIT_VALUES_CLEAR_ERRORS,
    EDIT_VALUES_ERROR,
    EDIT_VALUES_FULFILLED,
    EDIT_VALUES_PENDING
} from "../../constants/edition";

export const INITIAL_STATE = {
    pendings: {},
    errors: {},
}

export default function editionReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case EDIT_VALUES_PENDING : {
            const {saveId, values} = payload;
            if(state.pendings[saveId]) {
                console.error(`[EDIT_VALUES_PENDING] L'identifiant de sauvegarde savedId (${saveId}) n'est pas unique !`);
            }
            return {
                ...state,
                pendings: {
                    ...state.pendings,
                    [saveId]: values,
                }
            };
        }
        case EDIT_VALUES_FULFILLED : {
            const {saveId} = payload;
            if(!state.pendings[saveId]) {
                console.error(`[EDIT_VALUES_FULFILLED] L'identifiant de sauvegarde savedId (${saveId}) n'est plus dans la liste des sauvegardes !`);
            }

            const pendings = {...state.pendings};
            if(state.pendings[saveId]) {
                delete pendings[saveId];
            }

            return {
                ...state,
                pendings
            };
        }
        case EDIT_VALUES_ERROR : {
            const {saveId} = payload;
            if(!state.pendings[saveId]) {
                console.error(`[EDIT_VALUES_ERROR] L'identifiant de sauvegarde savedId (${saveId}) n'est plus dans la liste des sauvegardes !`);
            }
            if(state.errors[saveId]) {
                console.error(`[EDIT_VALUES_ERROR] L'identifiant de sauvegarde savedId (${saveId}) n'est pas unique !`);
            }

            const pendings = {...state.pendings};
            const errors = {...state.errors};
            if(state.pendings[saveId]) {
                errors[saveId] = state.pendings[saveId];
                delete pendings[saveId];
            }

            return {
                ...state,
                pendings,
                errors
            }
        }
        case EDIT_VALUES_CLEAR_ERROR : {
            const {saveId} = payload;
            if(!state.errors[saveId]) {
                console.error(`[EDIT_VALUES_CLEAR_ERROR] L'identifiant de sauvegarde savedId (${saveId}) n'est plus dans la liste des erreurs !`);
            }

            const errors = {...state.errors};
            if(state.errors[saveId]) {
                delete errors[saveId];
            }

            return {
                ...state,
                errors
            }
        }
        case EDIT_VALUES_CLEAR_ERRORS : {
            return {
                ...state,
                errors: {}
            }
        }
    }

    return state;
}