export const intChangeFormater = value => {
    if(`${value}` === '') return value;

    let formatedValue = `${value}`.replace(/[^0-9]/g, '');
    if(formatedValue === '0') return formatedValue;
    formatedValue = formatedValue.replace(/^0/, '');
    return formatedValue
}

export const intBlurFormater = value => {
    if(`${value}` === '') return 0;
    return intChangeFormater(value);
}

export const floatChangeFormater = value => {
    if(`${value}` === '') return value;

    let formatedValue = `${value}`.replace(/\./g, ',');
    formatedValue = formatedValue.replace(/[^0-9,]/g, '');
    let i = 0;
    formatedValue = formatedValue.replace(/,/g, () => {
        i++;
        return i > 1 ? '' : ',';
    });

    return formatedValue;
}

export const floatBlurFormater = value => {
    if(`${value}` === '') return 0;

    let formatedValue = `${value}`.replace(/\./g, ',');
    formatedValue = formatedValue.replace(/[^0-9,]/g, '');
    const parts = formatedValue.split(',');
    let intergerPart = parts[0];
    if(intergerPart.length === 0){
        intergerPart = '0';
    }
    let decimalPart = "";
    for(let i = 1; i < parts.length; i++) {
        decimalPart = `${decimalPart}${parts[i]}`;
    }
    if(decimalPart.length === 0) {
        return `${intergerPart}`;
    }

    return `${intergerPart},${decimalPart}`;
}

export const currencyChangeFormater = value => {
    if(`${value}` === '') return ' €';

    let formatedValue = `${value}`.replace(/\./g, ',');
    formatedValue = formatedValue.replace(/[^0-9,]/g, '');
    let i = 0;
    formatedValue = formatedValue.replace(/,/g, () => {
        i++;
        return i > 1 ? '' : ',';
    });

    return `${formatedValue} €`;
}


export const currencyBlurFormater = value => {
    if(`${value}` === '') return '0,00 €';

    let formatedValue = `${value}`.replace(/\./g, ',');
    formatedValue = formatedValue.replace(/[^0-9,]/g, '');
    const parts = formatedValue.split(',');
    let intergerPart = parts[0];
    if(intergerPart.length === 0){
        intergerPart = '0';
    } else {
        intergerPart = `${parseInt(intergerPart, 10)}`;
    }
    let decimalPart = "";
    for(let i = 1; i < parts.length; i++) {
        decimalPart += parts[i];
    }
    if(decimalPart.length === 0) {
        decimalPart = '00';
    } else if(decimalPart.length === 1) {
        decimalPart = `${decimalPart}0`;
    } else {
        decimalPart = decimalPart.slice(0, 2);
    }

    return `${intergerPart},${decimalPart} €`;
}

export const timeChangeFormater = value => {
    if(`${value}` === '') return 'h';

    let formatedValue = `${value}`.replace(/[^0-9h]/g, '');
    let i = 0;
    formatedValue = formatedValue.replace(/h/g, () => {
        i++;
        return i > 1 ? '' : 'h';
    });
    const matches = formatedValue.match(/([0-9][0-9])([0-9])h/);
    if(matches) {
        formatedValue = `${matches[1]}h${matches[2]}`;
    }

    let isHeure = true;
    let isMinute = false;
    let heure = '';
    let minute = '';
    for(let i = 0; i < formatedValue.length; i++) {
        const char = formatedValue.charAt(i);
        if(char === 'h') {
            isHeure = false;
            isMinute = true;
        } else {
            if (isHeure) {
                heure = `${heure}${char}`;
                if(heure.length === 2) isHeure = false;
            }
            if (isMinute) {
                minute = `${minute}${char}`;
                if(minute.length === 2) isMinute = false;
            }
        }
    }
    if(heure !== '' && parseInt(heure, 10) > 23) heure = '23';
    if(minute !== '' && parseInt(minute, 10) > 59) minute = '59';

    return `${heure}h${minute}`;
}

export const timeBlurFormater = value => {
    let formatedValue = timeChangeFormater(value);
    const matches = formatedValue.match(/([0-9]*)h([0-9]*)/);
    let heure = 0;
    let minute = 0;
    if(matches && matches[1] && matches[1] !== '') heure = parseInt(matches[1], 10);
    if(matches && matches[2] && matches[2] !== '') minute = parseInt(matches[2], 10);
    if(heure < 10) heure = `0${heure}`;
    if(minute < 10) minute = `0${minute}`;

    return `${heure}h${minute}`;
}

export const percentChangeFormater = value => {
    if(`${value}` === '') return '%';
    let formatedValue = `${value}`.replace(/[^0-9]/g, '');
    formatedValue = intChangeFormater(formatedValue);

    return `${formatedValue}%`;
}

export const percentBlurFormater = value => {
    if(`${value}` === '' || `${value}` === '%') return '0%';

    return percentChangeFormater(value);
}


export const valueChangeFormater = (value, type) => {
    if(type === 'int') {
        return intChangeFormater(value);
    }
    if(type === 'float') {
        return floatChangeFormater(value);
    }
    if(type === 'currency') {
        return currencyChangeFormater(value);
    }
    if(type === 'time') {
        return timeChangeFormater(value);
    }
    if(type === 'percent') {
        return percentChangeFormater(value);
    }

    return value;
}


export const valueBlurFormater = (value, type) => {
    if(type === 'int') {
        return intBlurFormater(value);
    }
    if(type === 'float') {
        return floatBlurFormater(value);
    }
    if(type === 'currency') {
        return currencyBlurFormater(value);
    }
    if(type === 'time') {
        return timeBlurFormater(value);
    }
    if(type === 'percent') {
        return percentBlurFormater(value);
    }

    return value;
}