const OutlinedInput = theme => ({
    root: {
        '&:hover $notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.secondary.main,
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
            '&:hover $notchedOutline': {
                borderColor: 'none',
            },
        },
        '&$focused $notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.secondary.main,
        },
        '&$error:hover $notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.error.dark,
        },
        '&$error$focused $notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.error.dark,
        },
        '&$disabled $notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[100],
        },
        '&$disabled .MuiInputAdornment-root': {
            color: theme.palette.grey[100]
        }
    },
    multiline: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    input: {
        padding: '13px 10px 13px 13px',
    },
    inputMarginDense: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    notchedOutline: {
        borderWidth: 1,
        borderColor: theme.palette.grey[200],
    }
});

export default OutlinedInput;
