import {
    OPTIMISATION_PANEL_TOGGLE
} from "../../constants/optimisation";

export const INITIAL_STATE = {
    isPanelOpen: false,
}

export default function optimisationReducer(state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case OPTIMISATION_PANEL_TOGGLE: {
            return {
                ...state,
                isPanelOpen: !state.isPanelOpen
            };
        }
    }

    return state;
}