import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {MONTANT_GHM} from "../../../../constants/simulationChamps";
import RecetteSejoursRow from "./RecetteSejoursRow";
import Paper from "../../../Simulation/Shared/Paper";
import Button from "@material-ui/core/Button";
import {addNewYearGhm} from "../../../../actions/defaultValues/defaultValuesAction";
import AddIcon from "@material-ui/icons/Add";


const useStyles = makeStyles(theme => ({
    rows: {
        display: "flex",
        flexWrap: "wrap",
        width: "50%"
    }
}));

const RecetteSejours = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const defaultMontantsGhms = useSelector(state => state.defaultValues.champs?.[MONTANT_GHM]?.value);

    if (!defaultMontantsGhms) return null;

    const checkRecetteSejours = (index) => {
        return !defaultMontantsGhms[index].error;
    }

    const showNewYear = () => {
        return parseInt(defaultMontantsGhms[0].annee) <= new Date().getFullYear();
    }

    const handleNewYear = () => {
        const {annee: anneePriv, value: valuePriv, statut: statutPriv} = defaultMontantsGhms[1];
        const {annee: anneePub, value: valuePub, statut: statutPub} = defaultMontantsGhms[0];

        dispatch(addNewYearGhm(MONTANT_GHM, valuePriv, (parseInt(anneePriv, 10) + 1).toString(), statutPriv));
        dispatch(addNewYearGhm(MONTANT_GHM, valuePub, (parseInt(anneePub, 10) + 1).toString(), statutPub));
    }

    return (
        <Paper title={t('simulation.recettesSejours')}>
            <div className={classes.rows}>
                {defaultMontantsGhms.map((defaultMontantsGhm, index) => (
                    <RecetteSejoursRow
                        key={`${defaultMontantsGhm.annee}-${defaultMontantsGhm.statut}`}
                        index={index}
                        annee={defaultMontantsGhm.annee}
                        statut={defaultMontantsGhm.statut}
                        value={defaultMontantsGhm.value}
                        errorChecker={checkRecetteSejours}
                    />
                ))}
            </div>
            {showNewYear() && (
                <Button variant="contained" onClick={handleNewYear} startIcon={<AddIcon />}>
                    {t('administration.champs.recettesSejours.new')}
                </Button>
            )}
        </Paper>
    );
}

RecetteSejours.propTypes = {
};

RecetteSejours.defaultProps = {
};

export default RecetteSejours;
