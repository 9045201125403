import {ContainedSecondaryButtonBase, OutlinedButtonBase} from "./Button";

const ToggleButton = theme => ({
    root: {
        padding: '9px 9px',
        ...theme.typography.body2,
        ...OutlinedButtonBase(theme),
        '&$selected': {
            ...ContainedSecondaryButtonBase(theme),
        },
        '&$sizeSmall': {
            textTransform: 'none',
            ...theme.typography.body2
        },
        '&$sizeLarge': {
            ...theme.typography.body2
        },
    },
    sizeSmall: {
        padding: '7px 9px',
    },
    sizeLarge: {
        padding: '12px 9px'
    },
});

export default ToggleButton;
