import { hot } from "react-hot-loader/root";
import React, {Suspense} from "react";
import PropTypes from "prop-types";
import {BrowserRouter as Router} from 'react-router-dom';
import { Provider } from "react-redux";
import I18nProvider from "./internationalization/I18nProvider";
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './Material/theme/theme'
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from "./routes/Routes";

const App = ({store}) => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Router>
                    <I18nProvider>
                        <Suspense fallback={null}>
                            <Routes />
                        </Suspense>
                    </I18nProvider>
                </Router>
            </ThemeProvider>
        </Provider>
    )
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default hot(App);