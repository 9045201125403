import React, {useState} from "react";
import PropTypes from "prop-types";
import DefaultValueDialogContent from "../Administration/DefaultValue/DefaultValueDialogContent";
import Settings from "@material-ui/icons/Settings";
import Dialog from "@material-ui/core/Dialog";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DefaultValuesProvider from "../../providers/DefaultValuesProvider";
import ContrastedTextButton from "../../Material/ContrastedTextButton";
import {useHasGRTExpertRole} from "../../managers/userManager";
import DefaultValueDialogTitle from "../Administration/DefaultValue/Header/DefaultValueDialogTitle";

const useStyles = makeStyles(theme => ({
    adminDialog: {
        borderRadius: "24px"
    },
    btn: {
        textTransform: 'none'
    }
}));

const HeaderDefaultValue = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const isGRTExpert = useHasGRTExpertRole();

    const handleClick = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false)
    }
    if (!isGRTExpert) {
        return null;
    }

    return (
        <>
            <ContrastedTextButton
                startIcon={<Settings />}
                onClick={handleClick}
                size="large"
                className={classes.btn}
            >
                {t('administration.header.defaultValue')}
            </ContrastedTextButton>
            <Dialog open={open} onClose={handleCancel} fullWidth={true} maxWidth="lg" className={classes.adminDialog}>
                <DefaultValueDialogTitle handleClose={handleCancel} />
                <DefaultValueDialogContent />
            </Dialog>
        </>
    );
}


HeaderDefaultValue.propTypes = {

};

HeaderDefaultValue.defaultProps = {

};

export default HeaderDefaultValue;