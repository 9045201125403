import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {logout} from "../../../actions/authentication/authenticationAction";
import {useTranslation} from "react-i18next";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ContrastedTextButton from "../../../Material/ContrastedTextButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {LOGIN_PAGE} from "../../../constants/router";
import {useHistory} from "react-router";
import useRouter from "../../../hooks/router/useRouter";

const useStyles = makeStyles(theme => ({
    btn: {
        textTransform: 'none'
    }
}));

const Logout = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const getUrl = useRouter();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(logout());
        history.push(getUrl({page: LOGIN_PAGE, keepParams: false, keepQuery: false}));
    };

    return (
        <ContrastedTextButton
            endIcon={<ArrowForwardRoundedIcon />}
            onClick={handleClick}
            size="large"
            className={classes.btn}
        >
            {t('login.logout')}
        </ContrastedTextButton>
    );
};

Logout.propTypes = {

};

Logout.defaultProps = {

};

export default Logout;



