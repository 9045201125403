import React from 'react'
import {getMatch, getRouteFromPath, getRouteName, getUrl} from "../../routes";
import {useLocation, useParams, useRouteMatch} from "react-router";
import qs from "qs";
import {ERROR_PAGES} from "../../constants/router";

const useRouter = () => {
    const location = useLocation();
    const match = getMatch(location.pathname);
    const prevPath = match.path;
    const prevParams = match.params;
    const prevQuery = qs.parse(location.search, {ignoreQueryPrefix: true});

    return ({
                page = null,
                params = {},
                query = {},
                locale = null,
                keepParams = true,
                keepQuery = true
            } = {}) => {
        return getUrl({prevPath, prevParams, prevQuery, page, params, query, locale, keepParams, keepQuery});
    };
};

export const usePageName = () => {
    const location = useLocation();
    const {pathname} = location;

    return getRouteName(pathname);
};

export const useIsPublicPage = () => {
    const location = useLocation();
    const {pathname} = location;
    const {path} = getMatch(pathname);
    const route = getRouteFromPath(path);

    return !route || route.isPublic;
};

export const useIsErrorPage = () => {
    const location = useLocation();
    const {pathname} = location;
    const pageName = getRouteName(pathname);

    return Object.values(ERROR_PAGES).indexOf(pageName) !== -1;
};

export default useRouter;