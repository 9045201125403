import {PERSPECTIVE_PANEL_OPEN, PERSPECTIVE_PANEL_SAVE_VALUE} from "../../constants/perspective";

export const INITIAL_STATE = {
    value1: 0,
    value2: 0,
    value3: 0,
    value4: 0,
}

export default function perspectiveValuesReducer(state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case PERSPECTIVE_PANEL_OPEN: {
            const {mainValue, benchmarkValue, isFloat} = payload;
            let values = [mainValue, mainValue, mainValue, benchmarkValue];
            if(mainValue !== benchmarkValue) {
                if(isFloat) {
                    const diff = Math.round(((benchmarkValue - mainValue) / 3) * 100) / 100;
                    values[1] = Math.round((mainValue + diff) * 100) / 100;
                    values[2] = Math.round((mainValue + (2 * diff)) * 100) / 100;
                } else {
                    const diff = Math.round((benchmarkValue - mainValue) / 3);
                    values[1] = mainValue + diff;
                    values[2] = mainValue + (2 * diff);
                }
            }

            return {
                ...state,
                value1: values[0],
                value2: values[1],
                value3: values[2],
                value4: values[3],
            }
        }
        case PERSPECTIVE_PANEL_SAVE_VALUE: {
            const {slug, value} = payload;

            return {
                ...state,
                [slug]: value
            }
        }
    }

    return state;
}