import {
    DEFAULT_VALUE_ADD_GHM,
    DEFAULT_VALUES_FETCH_ERROR,
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_FETCH_PENDING, DEFAULT_VALUES_SAVE, DEFAULT_VALUES_SAVE_ERROR, DEFAULT_VALUES_SAVE_FULFILLED,
    DEFAULT_VALUES_SET
} from "../../constants/defaultValues";
import apiFetch from "../../fetch";
import {MONTANT_GHM} from "../../constants/simulationChamps";

export const fetch = () => async (dispatch, getState) => {
    dispatch({type: DEFAULT_VALUES_FETCH_PENDING});
    try {
        const response = await apiFetch({isPublic: false, dispatch}).get(`/defaultsValues`);
        const {data} = response;
        dispatch({type: DEFAULT_VALUES_FETCH_FULFILLED, payload: {data}});
    } catch (error) {
        dispatch({type: DEFAULT_VALUES_FETCH_ERROR});
        console.error(error)
    }
}

export const setValue = (slug, value, index = null, error = false) => async (dispatch, getState) => {
    if (!slug || !value) return;
    dispatch({type: DEFAULT_VALUES_SET, payload: {slug, index, value, error}});
}

export const addNewYearGhm = (slug, value, annee, statut) => async (dispatch, getState) => {
    if (!slug || !value || !annee || !statut) return;
    dispatch({type: DEFAULT_VALUE_ADD_GHM, payload: {slug, value, annee, statut}});
}

export const save = () => async (dispatch, getState) => {
    // Formater correctement
    const defaultValues = getState().defaultValues.champs;
    if (!defaultValues) return;
    const params = [];
    Object.values(defaultValues).forEach((defaultValue) => {
        if (defaultValue.slug === MONTANT_GHM) {
            defaultValue.value.forEach((valueGhm) => {
                params.push({
                    annee: valueGhm.annee,
                    statut: valueGhm.statut,
                    value: valueGhm.value
                });
            })
        } else {
            params.push({
                slug: defaultValue.slug,
                value: defaultValue.value
            });
        }
    })
    dispatch({type: DEFAULT_VALUES_SAVE});
    try {
        const response = await apiFetch({isPublic: false, dispatch})
            .post('/defaultValue/save', {"champsValues": params});
        dispatch({type: DEFAULT_VALUES_SAVE_FULFILLED});
    } catch (error) {
        console.error('error : ', error);
        dispatch({type: DEFAULT_VALUES_SAVE_ERROR});
    }
}