import {
    ETABLISSEMENT_EDIT_IS_CLIENT,
    ETABLISSEMENT_EDIT_IS_CLIENT_ERROR,
    ETABLISSEMENT_EDIT_IS_CLIENT_FULFILLED,
    STATIC_DATA_FETCH_ERROR,
    STATIC_DATA_FETCH_FULFILLED,
    STATIC_DATA_FETCH_PENDING
} from "../../constants/staticData";
import regionReducer, {INITIAL_STATE as REGION_INITIAL_STATE} from "./regionReducer";
import departementReducer, {INITIAL_STATE as DEPARTEMENT_INITIAL_STATE} from "./departementReducer";
import etablissementReducer, {INITIAL_STATE as ETABLISSEMENT_INITIAL_STATE} from "./etablissementReducer";
import listEditionReducer , {INITIAL_STATE as LIST_EDITION_INITIAL_STATE} from "../edition/listEditionRecucer";
import {EDIT_VALUES_ERROR, EDIT_VALUES_FULFILLED, EDIT_VALUES_PENDING} from "../../constants/edition";
import {ACTION_LOGOUT} from "../../constants/authentication";

const INITIAL_STATE = {
    pending: false,
    fulfilled: false,
    error: false,
    regions: {},
    departements: {},
    etablissements: {},
    edition: LIST_EDITION_INITIAL_STATE
};

export default function staticDataReducer (state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case STATIC_DATA_FETCH_PENDING : {
            return {
                ...state,
                pending: true,
                fulfilled: false,
                error: false
            }
        }
        case STATIC_DATA_FETCH_FULFILLED : {
            const {data} = payload;
            const {regions, departements, etablissements} = data;

            return {
                ...state,
                pending: false,
                fulfilled: true,
                error: false,
                regions: Object.keys(regions).reduce((accu, idReg) => {
                    accu[idReg] = regionReducer(REGION_INITIAL_STATE, {type, payload: {...regions[idReg]}});
                    return accu;
                }, {}),
                departements: Object.keys(departements).reduce((accu, idReg) => {
                    accu[idReg] = departementReducer(DEPARTEMENT_INITIAL_STATE, {type, payload: {...departements[idReg]}});
                    return accu;
                }, {}),
                etablissements: Object.keys(etablissements).reduce((accu, idReg) => {
                    accu[idReg] = etablissementReducer(ETABLISSEMENT_INITIAL_STATE, {type, payload: {...etablissements[idReg]}});
                    return accu;
                }, {})
            }
        }
        case STATIC_DATA_FETCH_ERROR : {
            return {
                ...state,
                pending: false,
                fulfilled: false,
                error: true
            }
        }

        case ETABLISSEMENT_EDIT_IS_CLIENT :
        case ETABLISSEMENT_EDIT_IS_CLIENT_FULFILLED :
        case ETABLISSEMENT_EDIT_IS_CLIENT_ERROR : {
            const {id, saveId, isClient} = payload;
            let editionType = EDIT_VALUES_PENDING;
            if(type === ETABLISSEMENT_EDIT_IS_CLIENT_FULFILLED) editionType = EDIT_VALUES_FULFILLED;
            if(type === ETABLISSEMENT_EDIT_IS_CLIENT_ERROR) editionType = EDIT_VALUES_ERROR;

            const edition = listEditionReducer(state.edition, {type: editionType});
            const currentSaveId = type === ETABLISSEMENT_EDIT_IS_CLIENT
                ? edition.saveId
                : saveId;
            return {
                ...state,
                edition,
                etablissements: {
                    ...state.etablissements,
                    [id]: etablissementReducer(state.etablissements[id], {type, payload: {saveId: currentSaveId, isClient}})
                }
            }
        }
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }

    return state;
}
