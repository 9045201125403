import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    DEPENSE_AUTRE,
    DEPENSE_IDE,
    DEPENSE_MEDECIN,
    DEPENSE_STRUCTURE, TEMPS_IDE_SEJOUR_HEURE,
} from "../../../../constants/simulationChamps";
import Champ from "../../../Simulation/Edition/Champ/Champ";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    depensesDirectesRoot: {
    }
}));

const DepensesDirectes = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {value: depenseMedecin, error: errorDepenseMedecin} = useSelector(state => state.defaultValues.champs?.[DEPENSE_MEDECIN]);
    const {value: depenseIde, error: errorDepenseIde} = useSelector(state => state.defaultValues.champs?.[DEPENSE_IDE]);
    const {value: depenseStructure, error: errorDepenseStructure} = useSelector(state => state.defaultValues.champs?.[DEPENSE_STRUCTURE]);
    const {value: depenseAutre, error: errorDepenseAutre} = useSelector(state => state.defaultValues.champs?.[DEPENSE_AUTRE]);
    const errors = {
        [DEPENSE_MEDECIN]: errorDepenseMedecin,
        [DEPENSE_IDE] : errorDepenseIde,
        [DEPENSE_STRUCTURE]: errorDepenseStructure,
        [DEPENSE_AUTRE]: errorDepenseAutre
    };

    const errorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.depensesDirectes')}>
            <div className={classes.depensesDirectesRoot}>
                <Champ
                    type="currency"
                    slug={DEPENSE_MEDECIN}
                    className={editionClasses.champContainer}
                    defaultValue={depenseMedecin}
                    label={t(`simulation.champs.${DEPENSE_MEDECIN}.label`)}
                    helper={t(`simulation.champs.${DEPENSE_MEDECIN}.helper`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Champ
                    type="currency"
                    slug={DEPENSE_IDE}
                    className={editionClasses.champContainer}
                    defaultValue={depenseIde}
                    label={t(`simulation.champs.${DEPENSE_IDE}.label`)}
                    helper={t(`simulation.champs.${DEPENSE_IDE}.helper`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Champ
                    type="currency"
                    slug={DEPENSE_STRUCTURE}
                    className={editionClasses.champContainer}
                    defaultValue={depenseStructure}
                    label={t(`simulation.champs.${DEPENSE_STRUCTURE}.label`)}
                    helper={t(`simulation.champs.${DEPENSE_STRUCTURE}.helper`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
                <Champ
                    type="currency"
                    slug={DEPENSE_AUTRE}
                    className={editionClasses.champContainer}
                    defaultValue={depenseAutre}
                    label={t(`simulation.champs.${DEPENSE_AUTRE}.label`)}
                    helper={t(`simulation.champs.${DEPENSE_AUTRE}.helper`)}
                    onBlur={handleSave}
                    errorChecker={errorChecker}
                />
            </div>
        </Paper>
    );
};

DepensesDirectes.propTypes = {
};

DepensesDirectes.defaultProps = {
};

export default DepensesDirectes;