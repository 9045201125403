import React from "react";
import PropTypes from "prop-types";
import {HOME_PAGE} from "../../constants/router";
import logo from "../../assets/img/logo/optidol.svg";
import {useTranslation} from "react-i18next";
import useRouter from "../../hooks/router/useRouter";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    HeaderLogoHomePageLinkRoot: {

    },
    logo: {
        display: 'block',
    }
}));

const HeaderLogoHomePageLink = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const getUrl = useRouter();

    return (
        <Link
            className={classes.HeaderLogoHomePageLinkRoot}
            href={getUrl({page: HOME_PAGE, keepQuery: false, keepParams: false})}
        >
            <img className={classes.logo} alt={t('header.logoImgAlt')} width="120px" height="32px" src={logo} />
        </Link>
    );
};

HeaderLogoHomePageLink.propTypes = {

};

HeaderLogoHomePageLink.defaultProps = {

};

export default HeaderLogoHomePageLink;