import {
    DEFAULT_VALUES_FETCH_FULFILLED,
    DEFAULT_VALUES_SET
} from "../../constants/defaultValues";
import {formatNumber} from "../../managers/simulationValuesManager";
import {DEPENSE_AUTRE} from "../../constants/simulationChamps";

export const INITIAL_STATE = {
    slug: null,
    value: null,
    error: false
}

export default function defaultValueReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case DEFAULT_VALUES_FETCH_FULFILLED:{
            const {slug, value} = payload;
            return {
                ...state,
                slug,
                value
            }
        }
        case DEFAULT_VALUES_SET: {
            const {value, error, slug} = payload;
            const formatedValue = formatNumber(value, true);

            return {
                ...state,
                value: formatedValue,
                error: error || Number.isNaN(formatedValue) || (slug === DEPENSE_AUTRE ? false : formatedValue === 0)
            }
        }
    }

    return state;
}