import {
    AUTRE_RECETTE,
    COEFF_GEOGRAPHIQUE,
    CONSOMMABLE_PAR_SEJOUR,
    DEPENSE_AUTRE,
    DEPENSE_IDE,
    DEPENSE_MEDECIN,
    DEPENSE_STRUCTURE,
    JOUR_AM_HORAIRE,
    JOUR_AM_LIT,
    JOUR_PM_HORAIRE,
    JOUR_PM_LIT,
    JOURS,
    MONTANT_CONSOMMABLE,
    MONTANT_GHM,
    SEJOUR_ANNUEL,
    SERVICE_CONSOMMATION_PAR_ZONE,
    SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL,
    SERVICE_SEJOUR_TOTAL_ANNUEL,
    SERVICES,
    TEMPS_IDE_SEJOUR_HEURE,
    TEMPS_LIT_SEJOUR_HEURE
} from "../constants/simulationChamps";
import {useSelector} from "react-redux";
import {
    PARAM_OPTIMISATION_PLANNING,
    PARAM_OPTIMISATION_SERVICE
} from "../constants/optimisation";
import {
    PARAM_STATUT_ETAB_PRIVE,
    PARAM_TYPE_GEO_DEP,
    QUERY_PARAM_ID_DEP,
    QUERY_PARAM_ID_REG,
    QUERY_PARAM_IS_SDC_ETAB,
    QUERY_PARAM_STATUT_ETAB
} from "../constants/carte";
import numberFormatter from "./numberFormater";

export const convertValToTime = (val, hasSign) => {
    let sign = (val >= 0) ? 1 : -1;
    val *= sign;
    const hour = Math.floor(val);
    let decpart = val - hour;
    const min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    let minute = `${Math.floor(decpart * 60)}`;
    if (minute.length < 2) {
        minute = `0${minute}`;
    }
    sign = sign === 1 ? '+' : '-';
    if ( ! hasSign) {
        sign = '';
    }
    return `${sign+hour}h${minute}`;
};

export const formatWithSign = val => {
    if (val < 0) {
        return `-${val}`;
    }
    return `+${val}`;
};

export const formatNumber = (num, isFloat = false) => {
    let formatedNum = `${num}`;
    formatedNum = formatedNum.replace(',', '.');
    formatedNum = formatedNum.replace(/[^0-9.]/, '');
    formatedNum = isFloat
        ? Number(parseFloat(formatedNum, 10).toFixed(2))
        : parseInt(formatedNum, 10)
    ;
    return formatedNum;
};

const getJourAmSejour = (values, jour, valueProp = 'mainValue') => {
    const tempsLitSejourHeure = values[TEMPS_LIT_SEJOUR_HEURE][valueProp];
    return tempsLitSejourHeure === 0 ? 0 : Math.round((
        values[`${jour}${JOUR_AM_HORAIRE}`][valueProp] *
        values[`${jour}${JOUR_AM_LIT}`][valueProp]
    ) / tempsLitSejourHeure);
};

const getJourPmSejour = (values, jour, valueProp = 'mainValue') => {
    const tempsLitSejourHeure = values[TEMPS_LIT_SEJOUR_HEURE][valueProp];
    return tempsLitSejourHeure === 0 ? 0 : Math.round((
        values[`${jour}${JOUR_PM_HORAIRE}`][valueProp] *
        values[`${jour}${JOUR_PM_LIT}`][valueProp]
    ) / tempsLitSejourHeure);
};

const getJourSejour = (values, jour, valueProp = 'mainValue') => {
    return (getJourAmSejour(values, jour, valueProp) +
        getJourPmSejour(values, jour, valueProp));
};

const getServicePatientDnpAnnuel = (values, service, valueProp = 'mainValue') => {
    return (values[`${service}${SERVICE_SEJOUR_TOTAL_ANNUEL}`][valueProp] *
        values[`${service}${SERVICE_PART_SEJOUR_DOULOUREUX_ANNUEL}`][valueProp]);
}

export const getSejourAnnuel = (values, optimisation = null, valueProp = 'mainValue') => {
    if(optimisation === PARAM_OPTIMISATION_PLANNING) {
        return (JOURS.reduce((accu, jour) =>
            accu + getJourSejour(values, jour, valueProp),
            0
        ) * 52);
    }

    if(optimisation === PARAM_OPTIMISATION_SERVICE) {
        return (SERVICES.reduce((accu, service) =>
            accu + getServicePatientDnpAnnuel(values, service, valueProp),
            0
        ));
    }

    return values[SEJOUR_ANNUEL][valueProp];
};

export const getConsommableSejour = (values, optimisation = null, valueProp = 'mainValue') => {
    if(optimisation !== PARAM_OPTIMISATION_SERVICE) {
        return values[CONSOMMABLE_PAR_SEJOUR][valueProp];
    }
    const sejour = getSejourAnnuel(values, optimisation, valueProp);
    return sejour === 0 ? 0 : (
        SERVICES.reduce((accu, service) =>
            accu + (
                getServicePatientDnpAnnuel(values, service, valueProp) *
                values[`${service}${SERVICE_CONSOMMATION_PAR_ZONE}`][valueProp]
            ),
            0
        ) / sejour
    );
};

export const getDepenseIdeSejour = (values, valueProp = 'mainValue') => {
    return (values[TEMPS_IDE_SEJOUR_HEURE][valueProp] * values[DEPENSE_IDE][valueProp]);
};

export const getDepenseMedecinTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getSejourAnnuel(values, optimisation, valueProp) * values[DEPENSE_MEDECIN][valueProp]);
};

export const getDepenseIdeTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getDepenseIdeSejour(values, valueProp) * getSejourAnnuel(values, optimisation, valueProp));
};

export const getDepenseStructureTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getSejourAnnuel(values, optimisation, valueProp) * values[DEPENSE_STRUCTURE][valueProp]);
};

export const getDepenseAutreTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getSejourAnnuel(values, optimisation, valueProp) * values[DEPENSE_AUTRE][valueProp]);
};

export const getDepenseDirectTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (
        getDepenseMedecinTotal(values, optimisation, valueProp) +
        getDepenseIdeTotal(values, optimisation, valueProp) +
        getDepenseStructureTotal(values, optimisation, valueProp) +
        getDepenseAutreTotal(values, optimisation, valueProp)
    );
};

export const getDepenseDirectSejour = (values, valueProp = 'mainValue') => {
    return (
        values[DEPENSE_MEDECIN][valueProp] +
        getDepenseIdeSejour(values, valueProp) +
        values[DEPENSE_STRUCTURE][valueProp] +
        values[DEPENSE_AUTRE][valueProp]
    );
};

export const getCoutConsommableSejour = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getConsommableSejour(values, optimisation, valueProp) * values[MONTANT_CONSOMMABLE][valueProp]);
};

export const getCoutConsommableTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getCoutConsommableSejour(values, optimisation, valueProp) * getSejourAnnuel(values, optimisation, valueProp));
};

export const getRecetteSejour = (values, valueProp = 'mainValue') => {
    return (values[MONTANT_GHM][valueProp] * values[COEFF_GEOGRAPHIQUE][valueProp]) + values[AUTRE_RECETTE][valueProp];
};

export const getRecetteTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getRecetteSejour(values, valueProp) * getSejourAnnuel(values, optimisation, valueProp));
};

export const getMargeOperationnelleSejour = (values, optimisation = null, valueProp = 'mainValue') => {
    return (
        getRecetteSejour(values, valueProp) -
        getCoutConsommableSejour(values, optimisation, valueProp) -
        getDepenseDirectSejour(values, valueProp)
    );
};

export const getMargeOperationnelleTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getMargeOperationnelleSejour(values, optimisation, valueProp) * getSejourAnnuel(values, optimisation, valueProp));
};

export const getMargeBruteSejour = (values, optimisation = null, valueProp = 'mainValue') => {
    return (
        getRecetteSejour(values, valueProp) -
        getCoutConsommableSejour(values, optimisation, valueProp)
    );
};

export const getMargeBruteTotal = (values, optimisation = null, valueProp = 'mainValue') => {
    return (getMargeBruteSejour(values, optimisation, valueProp) * values[SEJOUR_ANNUEL][valueProp]);
};

const getTempsLitSejourSemaine = (values, optimisation = null, valueProp = 'mainValue') => {
    return ((
        getSejourAnnuel(values, optimisation, valueProp) *
        values[TEMPS_LIT_SEJOUR_HEURE][valueProp]
    ) / 52);
};

const getTempsIdeSejourSemaine = (values, optimisation = null, valueProp = 'mainValue') => {
    return ((
        getSejourAnnuel(values, optimisation, valueProp) *
        values[TEMPS_IDE_SEJOUR_HEURE][valueProp]
    ) / 52);
};

/*

    Résultat Actuel

    (
        panel édition : Sejours annuel *
        Panel édition : Temps fauteuil lit alloué à la pose
        / 52
    )
    /
    (
        (Panel édition : plage horaire lundi matin * Panel édition : Fauteuils lit alloués à la pose lundi matin) + (Panel édition : plage horaire lundi matin * Panel édition : Fauteuils lit alloués à la pose lundi matin) +
        (Panel édition : plage horaire mardi matin * Panel édition : Fauteuils lit alloués à la pose mardi matin) + (Panel édition : plage horaire mardi matin * Panel édition : Fauteuils lit alloués à la pose mardi matin) +
        (Panel édition : plage horaire mercredi matin * Panel édition : Fauteuils lit alloués à la pose mercredi matin) + (Panel édition : plage horaire mercredi matin * Panel édition : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )

    Optimisation simplifié

    (
        panel optimisation simplifié : Sejours annuel *
        Panel optimisation planning : Temps fauteuil lit alloué à la pose
        / 52
    )
    /
    (
        (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
        (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) +
        (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )

    Optimisation service
    (
        (
            Panel optimisation service : Séjours annuels Oncologie * Panel optimisation service : % de DNP Oncologie / 100 +
            Panel optimisation service : Séjours annuels Soins Palliatifs * Panel optimisation service : % de DNP Soins Palliatifs / 100 +
            Panel optimisation service : Séjours annuels Chirurgie Générale * Panel optimisation service : % de DNP Chirurgie Générale / 100 +
            ...
        ) *
        Panel optimisation planning : Temps fauteuil lit alloué à la pose
        / 52
    )
    /
    (
        (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
        (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) +
        (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )

    Optimisation planning
    (
        (
            (
                (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
                (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin)  +
                (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin)  +
                ...
            ) / Panel optimisation planning : Temps fauteuil lit alloué à la pose
            * Panel optimisation planning : Temps fauteuil lit alloué à la pose
        ) * 52
    ) *
        Panel optimisation planning : Temps fauteuil lit alloué à la pose / 52
    )
    /
    (
        (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) + (Panel optimisation planning : plage horaire lundi matin * Panel optimisation planning : Fauteuils lit alloués à la pose lundi matin) +
        (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) + (Panel optimisation planning : plage horaire mardi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mardi matin) +
        (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) + (Panel optimisation planning : plage horaire mercredi matin * Panel optimisation planning : Fauteuils lit alloués à la pose mercredi matin) +
        ...
    )
    sum jours / lit * 52 * lit / 52 / sum jours

    ((((12/1.5)*52)*1.5)/52)/12

*/
const getTauxOccupationLit = (values, optimisation = null, valueProp = 'mainValue') => {
    const sum = JOURS.reduce((accu, jour) =>
        accu + (
            values[`${jour}${JOUR_AM_HORAIRE}`][valueProp] *
            values[`${jour}${JOUR_AM_LIT}`][valueProp]
        ) + (
            values[`${jour}${JOUR_PM_HORAIRE}`][valueProp] *
            values[`${jour}${JOUR_PM_LIT}`][valueProp]
        ),
        0
    );
    return sum === 0
        ? 0
        : (getTempsLitSejourSemaine(values, optimisation, valueProp) / sum)
        ;
};

export const useSejourAnnuel = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getSejourAnnuel(values, optimisation, valueProp);
}

export const useJourSejour = (isBenchmark, jour) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values= useSelector(state => state.simulation.values);

    return getJourSejour(values, jour, valueProp);
}

export const useServicePatientDnpAnnuel = (isBenchmark, service) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getServicePatientDnpAnnuel(values, service, valueProp);
}

export const useConsommableMoyenSejour = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getConsommableSejour(values, optimisation, valueProp);
}

export const useDepenseDirecteTotal = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getDepenseDirectTotal(values, optimisation, valueProp);
}

export const useCoutConsommableTotal = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getCoutConsommableTotal(values, optimisation, valueProp);
}

export const useRecetteSejour = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getRecetteSejour(values, valueProp);
}

export const useTempsLitSejourSemaine = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getTempsLitSejourSemaine(values, optimisation, valueProp);
}

export const useTempsIdeSejourSemaine = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getTempsIdeSejourSemaine(values, optimisation, valueProp);
}
export const useTauxOccupationLit = (isBenchmark, optimisation = null) => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return getTauxOccupationLit(values, optimisation, valueProp) * 100;
}

export const getDiffPercent = (amount, avg, format = false) => {
    let diff = numberFormatter.getValue(Math.round(((amount - avg) / avg) * 100), true);
    if (format) {
        diff = numberFormatter.getValue(diff, true);
    }
    return diff > 0 ? `+${diff}` : `${diff}`;
};

export const getDiff = (value, benchValue, format = false) => {
    let diff = benchValue - value;
    if (format) {
        diff = numberFormatter.getValue(diff, true);
    }
    return diff > 0 ? `+${diff}` : `${diff}`;
};

export const useGetNbEtabsFromGeo = () => {
    const etablissements = useSelector(state => state.staticData.etablissements);

    return (geoType, id) => {
        const geoIdProp = geoType === PARAM_TYPE_GEO_DEP ? QUERY_PARAM_ID_DEP : QUERY_PARAM_ID_REG;
        return Object.keys(etablissements).filter(e => {
            return etablissements[e][geoIdProp] === id;
        }).length;
    };
};

export const useGetNbPrivateEtabsFromGeo = () => {
    const etablissements = useSelector(state => state.staticData.etablissements);
    return (geoType, id, checkIsSdc) => {
        const geoIdProp = geoType === PARAM_TYPE_GEO_DEP ? QUERY_PARAM_ID_DEP : QUERY_PARAM_ID_REG;
        return Object.keys(etablissements).filter(e => {
            if (checkIsSdc && ! etablissements[e][QUERY_PARAM_IS_SDC_ETAB]) {
                return false;
            }
            return etablissements[e][geoIdProp] === id && etablissements[e][QUERY_PARAM_STATUT_ETAB] === PARAM_STATUT_ETAB_PRIVE;
        }).length;
    };
};

export const useGetNbSDCFromGeo = () => {
    const etablissements = useSelector(state => state.staticData.etablissements);

    return (geoType, geoId) => {
        const geoIdProp = geoType === PARAM_TYPE_GEO_DEP ? QUERY_PARAM_ID_DEP : QUERY_PARAM_ID_REG;
        return Object.keys(etablissements).filter(e => {
            return etablissements[e].isSdc === true && etablissements[e][geoIdProp] === geoId;
        }).length;
    };
};

export const useGetAverageSDC = () => {
    const etablissements = useSelector(state => state.staticData.etablissements);
    const departements = useSelector(state => state.staticData.departements);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_DEP ? departements : regions;
        const total = Object.values(etablissements).reduce((t, {isSdc}) => t + isSdc, 0);
        return Math.round(total / Object.keys(geos).length);
    };
};

export const useGetAverageEtabs = () => {
    const etablissements = useSelector(state => state.staticData.etablissements);
    const departements = useSelector(state => state.staticData.departements);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_DEP ? departements : regions;
        const avg = Object.keys(etablissements).length / Object.keys(geos).length;
        return Math.round(avg);
    };
};

export const useGetAveragePopulation = () => {
    const departements = useSelector(state => state.staticData.departements);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_DEP ? departements : regions;
        const total = Object.values(geos).reduce((t, {dnp}) => t + dnp, 0);
        return Math.round(total / Object.keys(geos).length);
    };
};

export const useGetAveragePatients = () => {
    const departements = useSelector(state => state.staticData.departements);
    const regions = useSelector(state => state.staticData.regions);
    return (geoType) => {
        const geos = geoType === PARAM_TYPE_GEO_DEP ? departements : regions;
        const total = Object.values(geos).reduce((t, {patients}) => t + patients, 0);
        return Math.round(total / Object.keys(geos).length);
    };
};

