import React from "react";
import PropTypes from "prop-types";

const PublicLayout = ({children}) => {
    return (
        <>
            {children}
        </>
    );
};

PublicLayout.propTypes = {
    children: PropTypes.node
};

PublicLayout.defaultProps = {
    children: null
};

export default PublicLayout;