import React from "react";
import PropTypes from "prop-types";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    DialogCloseButtonRoot: {
        position: 'absolute',
        top: '12px',
        right: '12px',
    }
}));

const DialogCloseButton = ({handleClose}) => {
    const classes = useStyles();

    return (
        <IconButton
            className={classes.DialogCloseButtonRoot}
            aria-label="close"
            onClick={handleClose}
            color="secondary"
        >
            <CloseRoundedIcon />
        </IconButton>
    );
};

DialogCloseButton.propTypes = {
    handleClose: PropTypes.func
};

DialogCloseButton.defaultProps = {
    handleClose: () => null
};

export default DialogCloseButton;