import {
    PERSPECTIVE_PANEL_COSE,
    PERSPECTIVE_PANEL_OPEN,
    PERSPECTIVE_PANEL_SAVE_VALUE
} from "../../constants/perspective";
import {
    CONSOMMABLE_PAR_SEJOUR,
    PERSPECTIVE_CHAMPS,
    SEJOUR_ANNUEL,
    TEMPS_IDE_SEJOUR_HEURE, TEMPS_LIT_SEJOUR_HEURE
} from "../../constants/simulationChamps";
import perspectiveValuesReducer, {INITIAL_STATE as VALUES_INITIAL_STATE} from "./perspectiveValuesReducer";
import {getSejourAnnuel, getConsommableSejour} from "../../managers/simulationValuesManager";
import {PARAM_OPTIMISATION_PLANNING} from "../../constants/optimisation";


const INITIAL_STATE = {
    isPanelOpen: false,
    values: PERSPECTIVE_CHAMPS.reduce((accu, champ) => ({...accu, [champ]: VALUES_INITIAL_STATE}), {})
};

export default function perspectiveReducer(state = INITIAL_STATE, {type, payload}) {
    switch(type) {
        case PERSPECTIVE_PANEL_OPEN: {
            const {optimisation, values: simulationValues} = payload;
            const values = {...state.values};
            PERSPECTIVE_CHAMPS.forEach(champ => {
                if(champ === SEJOUR_ANNUEL) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: getSejourAnnuel(simulationValues, null),
                            benchmarkValue: getSejourAnnuel(simulationValues, optimisation, 'benchmarkValue'),
                            isFloat: false,
                        }
                    });
                } else if(champ === CONSOMMABLE_PAR_SEJOUR) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: getConsommableSejour(simulationValues, null),
                            benchmarkValue: getConsommableSejour(simulationValues, optimisation, 'benchmarkValue'),
                            isFloat: true,
                        }
                    });
                } else if(champ === TEMPS_IDE_SEJOUR_HEURE) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: simulationValues[champ].mainValue,
                            benchmarkValue: simulationValues[champ].benchmarkValue,
                            isFloat: true,

                        }
                    });
                } else if(champ === TEMPS_LIT_SEJOUR_HEURE && optimisation === PARAM_OPTIMISATION_PLANNING) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: simulationValues[champ].mainValue,
                            benchmarkValue: simulationValues[champ].benchmarkValue,
                            isFloat: true,
                        }
                    });
                } else {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: simulationValues[champ].mainValue,
                            benchmarkValue: simulationValues[champ].mainValue
                        }
                    });
                }
            });

            return {
                ...state,
                isPanelOpen: true,
                values
            };
        }
        case PERSPECTIVE_PANEL_COSE: {
            return {
                ...state,
                isPanelOpen: false,
            };
        }
        case PERSPECTIVE_PANEL_SAVE_VALUE: {
            const {type} = payload;

            return {
                ...state,
                values: {
                    ...state.values,
                    [type]: perspectiveValuesReducer(state.values[type], {type: PERSPECTIVE_PANEL_SAVE_VALUE, payload})
                }
            }
        }
    }

    return state;
};