import sizes from "../sizes";

const Container = theme => ({
    root: {
        '@media (min-width: 600px)': {
            paddingLeft: `${sizes.containerPadding}px`,
            paddingRight: `${sizes.containerPadding}px`,
        },
    },
});

export default Container;
