export const arrayRemove = (list, index) => {
    return [
        ...list.slice(0, index),
        ...list.slice(index + 1)
    ];
}
export const arrayAdd = (list, index, data) => {
    return [
        ...list.slice(0, index),
        data,
        ...list.slice(index)
    ];
}
export const arrayUpdate = (list, index, data) => {
    return [
        ...list.slice(0, index),
        data,
        ...list.slice(index + 1)
    ];
}

export const arrayPush = (list, data) => {
    const index = list.length;

    return arrayAdd(list, index, data);
}
