import React from "react";

class NumberFormatterFactory {

    constructor() {
        if (!NumberFormatterFactory.instance) {
            this.locale = 'fr-FR';
            this.int = this.getFormatter(0);
            this.float = this.getFormatter(2);
            NumberFormatterFactory.instance = this;
        }
        return NumberFormatterFactory.instance;
    }

    getPercent(value, total, isEvolution = false) {
        if (value === null || total === null) {
            return 'NA';
        }
        if(total === 0) {
            return this.getValue(0, true, isEvolution);
        }

        return this.getValue((value / total) * 100, true, isEvolution);
    }

    getValue(value, isFloat = false, isEvolution = false) {
        if (value === null) {
            return 'NA';
        }
        const hasDecimal = (value - Math.floor(value)) !== 0;
        const formatter = isFloat && hasDecimal || isEvolution
            ? this.float
            : this.int
        ;
        const val = formatter.format(value);
        return isEvolution && value > 0 ? `+${val}` : val;
    }

    getFormatter(nbDigits) {
        return new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: nbDigits,
            maximumFractionDigits: nbDigits,
        });
    }

}

const numberFormatter = new NumberFormatterFactory();
export default numberFormatter;
