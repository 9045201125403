import React, {useState} from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {MONTANT_GHM} from "../../../../constants/simulationChamps";
import Champ from "../../../Simulation/Edition/Champ/Champ";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import {useDispatch} from "react-redux";
import {Typography} from "@material-ui/core";
import {PARAM_STATUT_ETAB_PUBLIC} from "../../../../constants/carte";


const useStyles = makeStyles(theme => ({
    title: {
        height: "30px",
        marginBottom: "4px"
    },
    champContainer: {
        width: '160px',
        paddingRight: "16px"
    },
}));

const RecetteSejoursRow = ({index, annee, statut, value, errorChecker}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleSave = (savedSlug, inputValue) => {
        dispatch(setValue(MONTANT_GHM, inputValue, index));
    }

    const handleError = () => {
        return errorChecker(index);
    }

    return (
        <div>
            <div className={classes.title}>
                {statut === PARAM_STATUT_ETAB_PUBLIC && (
                    <Typography variant="h5" color="secondary">{annee}</Typography>
                )}
            </div>
            <Typography>{t(`administration.champs.statut.${statut}`)}</Typography>
            <Champ
                type="currency"
                className={classes.champContainer}
                slug={MONTANT_GHM + '-'+ index}
                defaultValue={value}
                onBlur={handleSave}
                errorChecker={handleError}
            />
        </div>
    );
}

RecetteSejoursRow.propTypes = {
    index: PropTypes.number.isRequired,
    annee: PropTypes.string,
    statut: PropTypes.string,
    value: PropTypes.number,
    errorChecker: PropTypes.func
};

RecetteSejoursRow.defaultProps = {
    annee: '',
    statut: 'priv',
    value: 0
};

export default RecetteSejoursRow;
