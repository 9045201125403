import React, {useState, forwardRef, useRef} from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import {valueBlurFormater, valueChangeFormater} from "./valueFormater";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const useStyles = makeStyles(theme => ({
    ChampRoot: {
        width: '100%'
    }
}));

let isInputFullSelection = false;

const Champ = forwardRef((props, outerRef) => {
    const {
        className,
        type,
        slug,
        defaultValue,
        size,
        helper,
        noHelper,
        label,
        selectOptions,
        onBlur,
        errorChecker,
        onReset
    } = props;

    const innerRef = useRef();
    const classes = useStyles();
    const [value, setValue] = useState(valueBlurFormater(defaultValue, type));

    const ref = outerRef || innerRef;
    const check = errorChecker(slug, value);

    const handleChange = event => {
        const value = valueChangeFormater(event.target.value, type);
        if(isInputFullSelection) {
            setTimeout(() => {
                event.target.selectionStart = 1;
                event.target.selectionEnd = 1;
            }, 10);
        }
        isInputFullSelection = false;
        setValue(value);
    }
    const handleBlur = event => {
        const value = valueBlurFormater(event.target.value, type);
        setValue(value);
        onBlur(slug, value);
    }

    const handleSelectClose = (event) => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            setTimeout(() => {
                document.activeElement.blur();
            }, 0);
        }
        if(event.keyCode >= 48 && event.keyCode <= 57) {
            isInputFullSelection = event.target.selectionEnd - event.target.selectionStart === value.length;
        }
    }
    const handleKeyUp = event => {
        if(event.target.value === '%' || event.target.value === ' €' || event.target.value === 'h') {
            event.target.selectionStart = 0;
            event.target.selectionEnd = 0;
        }
    }

    const handleClickReset = () => {
        if(onReset) {
            onReset(slug);
        }
    };

    const handleMouseDownReset = (event) => {
        event.preventDefault();
    };

    return (
        <div className={cn(classes.ChampRoot, className)}>
            {label && (
                <InputLabel
                    htmlFor={slug}
                    error={!check}
                >
                    {label}
                </InputLabel>
            )}
            <TextField
                id={slug}
                inputRef={ref}
                type="text"
                name={slug}
                value={value}
                fullWidth={true}
                size={size}
                variant="outlined"
                select={selectOptions !== null}
                SelectProps={
                    selectOptions !== null ? {onClose: handleSelectClose, IconComponent: ExpandMoreRoundedIcon} : {}
                }
                inputProps={selectOptions === null ? {onKeyDown: handleKeyDown, onKeyUp: handleKeyUp} : {}}
                InputProps={onReset === null ? null : {
                    endAdornment : (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickReset}
                                onMouseDown={handleMouseDownReset}
                            >
                                {<RefreshRoundedIcon/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!check}
            >
                {selectOptions !== null && selectOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            {!noHelper && (
                <FormHelperText error={!check}>
                    {helper === null ? <span>&nbsp;</span> : helper}
                </FormHelperText>
            )}
        </div>
    );
});

Champ.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    slug: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOf(['small', 'medium']),
    helper: PropTypes.string,
    noHelper: PropTypes.bool,
    label: PropTypes.string,
    selectOptions: PropTypes.array,
    onBlur: PropTypes.func,
    errorChecker: PropTypes.func,
    onReset: PropTypes.func
};

Champ.defaultProps = {
    className: '',
    type: 'int',
    defaultValue: 0,
    size: 'medium',
    helper: null,
    noHelper: false,
    label: null,
    selectOptions: null,
    onBlur: () => {},
    errorChecker: () => true,
    onReset: null
};

export default Champ;