import {
    ACTION_CLEAN_SEARCH,
    ACTION_FETCH_SEARCH_ERROR,
    ACTION_FETCH_SEARCH_FULFILLED,
    ACTION_FETCH_SEARCH_PENDING,
    ACTION_TOGGLE_SEARCH
} from "../../constants/carte";
import {ACTION_LOGOUT} from "../../constants/authentication";
import {
    SIMULATION_CREATION_SUCCESS,
    SIMULATION_FETCH_REMOVE,
    SIMULATION_RENAME_FULFILLED
} from "../../constants/simulation";
import {getNextRenamedSimulation} from "./simulationsListReducer";

const INITIAL_STATE = {
    searchPane: false,
    term: null,
    idReg: null,
    idDep: null,
    idEtab: null,
    typeGeo: null,
    population: null,
    isSdc: null,
    statut: null,
    champ: null,
    isClient: null,
    etablissements: null,
}

export default function searchReducer (state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case ACTION_TOGGLE_SEARCH: {
            const {idReg, idDep, idEtab, typeGeo, population, isSdc, statut, champ, isClient, close, open} = payload;

            if (idReg === state.idReg && idDep === state.idDep && idEtab === state.idEtab && typeGeo === state.typeGeo
                && population === state.population && isSdc === state.isSdc && statut === state.statut
                && champ === state.champ && isClient === state.isClient && !close) return state;

            return {
                ...state,
                searchPane: !state.searchPane,
                term: state.searchPane ? null : state.term,
                idReg: state.searchPane ? null : idReg,
                idDep: state.searchPane ? null : idDep,
                idEtab: state.searchPane ? null : idEtab,
                typeGeo: state.searchPane ? null : typeGeo,
                population: state.searchPane ? null : population,
                isSdc: state.searchPane ? null : isSdc,
                statut: state.searchPane ? null : statut,
                champ: state.searchPane ? null : champ,
                isClient: state.searchPane ? null : isClient,
                etablissements: state.searchPane ? null : state.etablissements,
            };
        }
        case ACTION_FETCH_SEARCH_PENDING: {
            const {params} = payload;
            const {search:term} = params;

            return {
                ...state,
                term,
                error: false,
                pending: true
            }
        }
        case ACTION_FETCH_SEARCH_FULFILLED: {
            const {params, data} = payload;
            const {search:term} = params;

            if (term !== state.term) return state;
            return {
                ...state,
                etablissements: data,
                pending: false
            }
        }
        case ACTION_FETCH_SEARCH_ERROR: {
            const {params} = payload;
            const {search:term} = params;

            if (term !== state.term) return state;
            return {
                ...state,
                pending: false,
                error: true
            }
        }
        case ACTION_CLEAN_SEARCH: {
            const {term} = payload;
            if (term === state.term) return state;

            return {
                ...state,
                term: null,
                pending: false,
                etablissements: null,
            };
        }
        case SIMULATION_RENAME_FULFILLED: {
            return getNextRenamedSimulation(state, payload);
        }
        case SIMULATION_CREATION_SUCCESS:
        case SIMULATION_FETCH_REMOVE:
        case ACTION_LOGOUT: {
            return {
                ...state,
                ...INITIAL_STATE
            }
        }
    }

    return state;
}