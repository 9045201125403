import makeStyles from "@material-ui/core/styles/makeStyles";

const useEditionStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        paddingBottom: '24px',
        color: theme.palette.text.secondary
    },
    champContainer: {
        position: 'relative',
        width: '280px',
        marginBottom: '12px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    infosContainer: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    infosIcon: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infosLabel: {

    },
    infosNumber: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    infosCaption: {
        color: theme.palette.text.disabled
    }
}));

export default useEditionStyles;