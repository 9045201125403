import {
    SIMULATION_FETCH_FULFILLED,
    SIMULATION_RENAME,
    SIMULATION_RENAME_ERROR,
    SIMULATION_RENAME_FULFILLED
} from "../../constants/simulation";
import editionReducer, {INITIAL_STATE as EDITION_INITIAL_STATE} from "../edition/editionReducer";
import {EDIT_VALUES_ERROR, EDIT_VALUES_FULFILLED, EDIT_VALUES_PENDING} from "../../constants/edition";

export const INITIAL_STATE = {
    idEtab: null,
    lib: null,
    edition: EDITION_INITIAL_STATE,
}

export default function simulationInfosReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case SIMULATION_FETCH_FULFILLED: {
            const {idEtab, lib} = payload;

            return {
                ...state,
                idEtab,
                lib
            }
        }
        case SIMULATION_RENAME: {
            const {lib, saveId} = payload;

            return {
                ...state,
                lib,
                edition: editionReducer(EDIT_VALUES_PENDING, {
                    saveId,
                    values: {
                        lib: [state.lib, lib]
                    }
                })
            }
        }
        case SIMULATION_RENAME_FULFILLED: {
            const {saveId, lib} = payload;

            return {
                ...state,
                lib,
                edition: editionReducer(EDIT_VALUES_FULFILLED, {saveId, lib})
            }
        }
        case SIMULATION_RENAME_ERROR: {
            const {saveId} = payload;
            const edition = editionReducer(EDIT_VALUES_ERROR, {saveId});
            return {
                ...state,
                lib: edition.errors[saveId].lib[0],
                edition
            }
        }
    }
}