import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import UiPaper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import sizes from "../../../Material/theme/sizes";
import useEditionStyles from "./useEditionStyles";

const useStyles = makeStyles(theme => ({
    PaperRoot: {
        backgroundColor: '#F7FAFA',
        marginLeft: `${sizes.paperPadding}px`,
        marginRight: `${sizes.paperPadding}px`,
        marginBottom: `${2*sizes.paperPadding}px`,
        padding: '30px 40px',
        borderRadius: `${sizes.paperRadius}px`,
    },
    header: withRightComponent => {
        return {
            display: 'flex',
            justifyContent: withRightComponent ? 'space-between' : 'center',
            alignItems: 'center'
        }
    },
    emptyLeft: {
        width: '25%',
    },
    headerRightComponent: {
        width: '25%',
        paddingBottom: '24px'
    }
}));

const Paper = ({className, children, title, headerRightComponent}) => {
    const classes = useStyles(!!headerRightComponent);
    const editionClasses = useEditionStyles();
    return (
        <UiPaper
            className={cn(className, classes.PaperRoot)}
            elevation={0}
        >
            <div className={classes.header}>
                {headerRightComponent && <span className={classes.emptyLeft} />}
                <Typography className={editionClasses.title} variant="h5">
                    {title}
                </Typography>
                {headerRightComponent && <span className={classes.headerRightComponent}>{headerRightComponent}</span>}
            </div>

            {children}
        </UiPaper>
    );
};

Paper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    headerRightComponent: PropTypes.node
};

Paper.defaultProps = {
    className: '',
    children: null,
    title: null,
    headerRightComponent: null
};

export default Paper;