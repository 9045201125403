import {
    QUERY_PARAM_TYPE_GEO,
    QUERY_PARAM_ID_REG,
    QUERY_PARAM_ID_DEP,
    QUERY_PARAM_ID_ETAB,
    QUERY_PARAM_POPULATION,
    QUERY_PARAM_IS_SDC_ETAB,
    QUERY_PARAM_STATUT_ETAB,
    QUERY_PARAM_CHAMP_ETAB,
    QUERY_PARAM_IS_CLIENT_ETAB, PARAM_TYPE_GEO_REG, PARAM_POPULATION_DNP, PARAM_TYPE_GEO_DEP
} from "../constants/carte";
import {useHistory} from "react-router";
import useRouter from "../hooks/router/useRouter";
import {useSelector} from "react-redux";
import useQuery from "../hooks/router/useQuery";

export const getDefaultParams = () => ({
    [QUERY_PARAM_TYPE_GEO]: PARAM_TYPE_GEO_REG,
    [QUERY_PARAM_ID_REG]: null,
    [QUERY_PARAM_ID_DEP]: null,
    [QUERY_PARAM_ID_ETAB]: null,
    [QUERY_PARAM_POPULATION]: PARAM_POPULATION_DNP,
    [QUERY_PARAM_IS_SDC_ETAB]: null,
    [QUERY_PARAM_STATUT_ETAB]: null,
    [QUERY_PARAM_CHAMP_ETAB]: null,
    [QUERY_PARAM_IS_CLIENT_ETAB]: null,
});

export const isSimulationsListParamsHasChange = (prevParams, nextParam) => (
    prevParams[QUERY_PARAM_ID_REG] !== nextParam[QUERY_PARAM_ID_REG] ||
    prevParams[QUERY_PARAM_ID_DEP] !== nextParam[QUERY_PARAM_ID_DEP] ||
    prevParams[QUERY_PARAM_STATUT_ETAB] !== nextParam[QUERY_PARAM_STATUT_ETAB] ||
    prevParams[QUERY_PARAM_CHAMP_ETAB] !== nextParam[QUERY_PARAM_CHAMP_ETAB] ||
    prevParams[QUERY_PARAM_IS_SDC_ETAB] !== nextParam[QUERY_PARAM_IS_SDC_ETAB] ||
    prevParams[QUERY_PARAM_IS_CLIENT_ETAB] !== nextParam[QUERY_PARAM_IS_CLIENT_ETAB]
);

export const useChangeIdReg = () => {
    const history = useHistory();
    const getUrl = useRouter();
    const idEtab = useQuery(QUERY_PARAM_ID_ETAB, getDefaultParams()[QUERY_PARAM_ID_ETAB]);
    const etablissements = useSelector(state => state.staticData.etablissements);

    return (idReg, otherQuery = {}) => {
        idReg = !idReg ? null : parseInt(idReg, 10);
        const nextQuery = {
            ...otherQuery,
            [QUERY_PARAM_TYPE_GEO]: PARAM_TYPE_GEO_REG,
            [QUERY_PARAM_ID_REG]: idReg,
            [QUERY_PARAM_ID_DEP]: null,
            [QUERY_PARAM_ID_ETAB]: null,
        };
        if(idEtab && etablissements[idEtab].idReg === idReg) {
            nextQuery[idEtab] = idEtab;
        }
        history.push(getUrl({
            query: nextQuery
        }));
    }
}
export const useChangeIdDep = () => {
    const history = useHistory();
    const getUrl = useRouter();
    const idEtab = useQuery(QUERY_PARAM_ID_ETAB, getDefaultParams()[QUERY_PARAM_ID_ETAB]);
    const etablissements = useSelector(state => state.staticData.etablissements);

    return (idDep, otherQuery = {}) => {
        idDep = !idDep ? null : parseInt(idDep, 10);
        const nextQuery = {
            ...otherQuery,
            [QUERY_PARAM_TYPE_GEO]: PARAM_TYPE_GEO_DEP,
            [QUERY_PARAM_ID_DEP]: idDep,
            [QUERY_PARAM_ID_REG]: null,
            [QUERY_PARAM_ID_ETAB]: null,
        };
        if(idEtab && etablissements[idEtab].idDep === idDep) {
            nextQuery[idEtab] = idEtab;
        }
        history.push(getUrl({
            query: nextQuery
        }));
    }
}

export const useChangeIdEtab = () => {
    const history = useHistory();
    const getUrl = useRouter();

    const typeGeo = useQuery(QUERY_PARAM_TYPE_GEO, getDefaultParams()[QUERY_PARAM_TYPE_GEO]);
    const etablissements = useSelector(state => state.staticData.etablissements);

    return (idEtab, otherQuery = {}) => {
        idEtab = !idEtab ? null : parseInt(idEtab, 10);
        const nextQuery = {
            ...otherQuery,
            [QUERY_PARAM_ID_ETAB]: idEtab,
            [QUERY_PARAM_ID_REG]: typeGeo === PARAM_TYPE_GEO_REG ? etablissements[idEtab].idReg : null,
            [QUERY_PARAM_ID_DEP]: typeGeo === PARAM_TYPE_GEO_DEP ? etablissements[idEtab].idDep : null,
        }

        history.push(getUrl({
            query: nextQuery
        }));
    }
}